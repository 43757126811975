import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Row } from "reactstrap";
import {encode as base64_encode, decode as base64_decode} from 'base-64';
import { BASE_URL } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { APP_ID } from "Api/Api";
import swal from "sweetalert";
import Loader from "components/Loader/Loader";
import React from "react";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Indicator from "components/Loader/Indicator";

const UpdateCourse = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [name,setName] = useState("")
  const [period,setPeriod] = useState("")
  const [fees,setFees] = useState("")
  const [discount,setDiscount] = useState("")
  const [details,setDetails] = useState("")
  const admin_id = localStorage.getItem('user_id')
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")
  const [photo, setPhoto] = useState("")

  if (!user_id || role!=="admin") {
    history.push("/")
  }

  document.addEventListener("DOMContentLoaded", function () {
    history.push(location.pathname)
  })
  
  const course = useParams()

  useEffect(()=>{
    setIsLoading(true)
    fetch(`${BASE_URL}get-single-course/${course.course_id}?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(res=>res.json())
    .then(data=>{
      setIsLoading(false)
      setName(data.data.name)
      setPeriod(data.data.period)
      setFees(data.data.fees)
      setDiscount(data.data.discount)
      setDetails(data.data.details)
      // alert(data.discount_price)
    })
  },[course.course_id])

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);


  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return base64_decode(course.course_name);
  };

  const updateCourseFunction = (event) =>{
    event.preventDefault()
    setLoading(true)
    if (photo) {
      const formData = new FormData();
      formData.append("course_id", course.course_id)
      formData.append("name", base64_encode(name))
      formData.append("period", base64_encode(period))
      formData.append("photo", photo)
      formData.append("fees", base64_encode(fees))
      formData.append("discount", base64_encode(discount))
      formData.append("details", base64_encode(details.toString()))
      formData.append("updated_by", base64_encode(admin_id))
      fetch(`${BASE_URL}update-course?app_id=${APP_ID}&key=${APP_KEY}`, {method:"PUT", body:formData})
      .then(res=>res.json())
      .then(data=>{
          if (data.result==="SUCCESS") {
              setLoading(false)
              swal({
                  title: data.result,
                  text: data.message,
                  icon: "success",
                  button: "Okay",
              });
          } else {
              setLoading(false)
              swal({
                  title: data.result,
                  text: data.message,
                  icon: "warning",
                  button: "Okay",
              });
          }
      })
      .catch((err)=>{
          setLoading(false)
          swal({
              title: "Network Request Error",
              text: err.message,
              icon: "warning",
              button: "Okay",
          });
      })
    } else {
      const formData = new FormData();
      formData.append("course_id", course.course_id)
      formData.append("name", base64_encode(name))
      formData.append("period", base64_encode(period))
      formData.append("fees", base64_encode(fees))
      formData.append("discount", base64_encode(discount))
      formData.append("details", base64_encode(details.toString()))
      formData.append("updated_by", base64_encode(admin_id))
      fetch(`${BASE_URL}update-course?app_id=${APP_ID}&key=${APP_KEY}`, {method:"PUT", body:formData})
      .then(res=>res.json())
      .then(data=>{
          if (data.result==="SUCCESS") {
              setLoading(false)
              swal({
                  title: data.result,
                  text: data.message,
                  icon: "success",
                  button: "Okay",
              });
          } else {
              setLoading(false)
              swal({
                  title: data.result,
                  text: data.message,
                  icon: "warning",
                  button: "Okay",
              });
          }
      })
      .catch((err)=>{
          setLoading(false)
          swal({
              title: "Network Request Error",
              text: err.message,
              icon: "warning",
              button: "Okay",
          });
      })
    }  
  }

  // alert(discount)

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../../assets/img/brand/logo.png"),
          imgAlt: "..."
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <>{isLoading===true ? (<Indicator/>) :(
        <>
        <PlainHeader />
        <CardBody>
            <Card>
                <CardBody>
                    <Form onSubmit={(event)=>updateCourseFunction(event)}>
                        <h6 className="heading-small text-muted mb-4">
                        <Button
                            color="primary"
                            // href="#pablo"
                            onClick={() => history.goBack()}
                            size="sm"
                            >
                            <i className="fa fa-angle-double-left"></i> back
                        </Button> {base64_decode(course.course_name)}
                        </h6>
                        <div className="pl-lg-4">
                            
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-course-name"
                                        >
                                        Course name
                                        </label>
                                        <Input
                                        className="form-control-alternative"
                                        id="input-course-name"
                                        placeholder="Course name"
                                        type="text"
                                        defaultValue={name}
                                        onChange={(e)=>setName(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-period"
                                        >
                                        Course Period
                                        </label>
                                        <Input
                                        className="form-control-alternative"
                                        id="input-period"
                                        placeholder="Course Period"
                                        type="number"
                                        min={1}
                                        defaultValue={period}
                                        onChange={(e)=>setPeriod(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-course-fees"
                                        >
                                        Course Fees
                                        </label>
                                        <Input
                                        className="form-control-alternative"
                                        id="input-course-fees"
                                        placeholder="Course Fees"
                                        type="number"
                                        defaultValue={fees}
                                        onChange={(e)=>setFees(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>


                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-course-discount"
                                        >
                                        Course Discount
                                        </label>
                                        <Input
                                        className="form-control-alternative"
                                        id="input-course-discount"
                                        placeholder="Course Discount"
                                        type="number"
                                        defaultValue={discount}
                                        onChange={(e)=>setDiscount(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>

                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-course-photo"
                                        >
                                        Photo
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-course-photo"
                                        placeholder="Course Fees"
                                        type="file"
                                        defaultValue={photo}
                                        onChange={(e)=>setPhoto(e.target.files[0])}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col lg="12">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-course-Details"
                                        >
                                        Details
                                        </label>
                                        <textarea
                                        className="form-control"
                                        rows={10}
                                        id="input-course-Details"
                                        placeholder="Details"
                                        required
                                        onChange={(e)=>setDetails(e.target.value)}
                                        >{details}</textarea>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {loading===true ? (
                                <Loader/>
                            ):(
                                <Button color="primary">
                                Update
                                </Button>
                            )}
                        </div>
                        <hr className="my-4" />
                    </Form>
                </CardBody>
            </Card>
        </CardBody>
        </>
        )}</>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default UpdateCourse;
