import {encode as base64_encode} from 'base-64';


//export const BASE_URL = `http://127.0.0.1:5000/api-gtw-/v1/`;
export const BASE_URL = `https://sandc-chuo-portal.leoleo.africa/api-gtw-/v1/`;
export const LEOLEO_URL = `https://management.leoleo.africa/leoleo-guliosmart-gtw/v1/`;
export const SELCOM_URL = `https://leoleotoken.sandc.co.tz/sandc_payment/`

const app_id = `23548019`;
const app_key = `SANDC-TECHNOLOGY-COMPANY-LIMITED0de7db36d052e16fe6123f4e45fb2994c199a35c84f0239d61d1ca095f56ff54b36570d3aad426b97222f1362bc8817e`;
export const APP_ID = base64_encode(app_id);
export const APP_KEY = base64_encode(app_key);

// ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABgQDEnUVnj+M9P2d8DYxE2gLxkR96R34oV3tkjLyYqdbdH5PYRUjpwF09IhpFYGlwycjuqSAoNLcuU/gJBZf9yHxUj4gluBKDJu0lKD55VUDj+7XZ3fnLyI4w7YCclJtemYGgZ9PMBj2+IPBKqpwo65zTz+fNw75j/84U7C/LF25M0hfIbQvknWOA7zFAxllgqA80WJ9/ZHpCfLc9hpdYrZyjTHlMBRPxOAbb04/yJtVNJ1i2QK1yJScaRyxY//ZI+WhJNVzJpCSUmb3xo/Ehcrfv/Cv3yNKVDRRJ5rcLVeK5sTW6OcOe41CmcKxPzgRP2YBnwqVKUPtCKUQQ4H2bACWkh8pVRnO/sVRoymjD0fab1BFSvIRuSsfXZdO1yez4P2r/Pjrt1Y2+6y1jjKI2T50i0VcK13LalhrEhI483x+swx9X0YIqYscRbpQvMVp0riJyO2qutU8M49PbC1s63YuczmSzbsYBxD1B20xGdATCEJRvd2XND7CX0Ttpw+IOTqc= root@0102afc86b53
