import { useEffect, useState } from "react";
import classnames from "classnames";
import Chart from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Table,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardTitle
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BASE_URL } from "Api/Api";
import { APP_ID } from "Api/Api";
import { APP_KEY } from "Api/Api";
import {encode as base64_encode} from 'base-64';
import swal from "sweetalert";
import Indicator from "components/Loader/Indicator";
import PlainHeader from "components/Headers/PlainHeader";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import { chartExample1 } from "variables/charts";
import { Colors } from "variables/Colors";


const Index = (props) => {
  const [activeNav, setActiveNav] = useState(1);
  const [chartExample1Data, setChartExample1Data] = useState("data1");
  const [courses, setCourses] = useState([])
  const [adminCourses, setadminCourses] = useState([])
  const [loading, setLoading] = useState(false)
  const [countDatas,setCountDatas] = useState({})

  const history = useHistory()
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")

  const [studentsChart, setstudentsChart] = useState([])
  const [coursesChart, setcoursesChart] = useState([])

  if (!user_id) {
    history.push("/")
  }

  useEffect(()=>{
    fetch(`${BASE_URL}count-datas?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(res=>res.json())
    .then(data=>{
      setCountDatas(data)
    })

    const Url = `${BASE_URL}get-all-courses-students-statistics?app_id=${APP_ID}&key=${APP_KEY}`
    fetch(Url)
    .then(res=>res.json())
    .then(data=>{
      setstudentsChart(data.students)
      setcoursesChart(data.courses)
    })
  },[])


  let chartOne = {
    data1: (canvas) => {
      return {
        labels: coursesChart,
        datasets: [
          {
            label: "STATISTICS",
            data: studentsChart,
            backgroundColor: Colors,
            borderColor: Colors
          },
        ]
      };
    }
  };


  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data("data" + index);
  };


  useEffect(()=>{
      fetch(`${BASE_URL}get-latest-courses-registed?app_id=${APP_ID}&key=${APP_KEY}`)
      .then(res=>res.json())
      .then(data=>{
          setadminCourses(data.data)
          $(document).ready(function () {
            $('#coursesTable').DataTable();
          });
      })
  },[])

  const deleteCourse = (id)=>{
      swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this course!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
      })
        .then((willDelete) => {
          if (willDelete) {
              fetch(`${BASE_URL}delete-course-route/${base64_encode(id.toString())}?app_id=${APP_ID}&key=${APP_KEY}`)
              .then(del=>del.json())
              .then(del=>{
                  swal(`Poof! ${del.message}!`, {
                      icon: "success",
                  });
                  fetch(`${BASE_URL}get-latest-courses-registed?app_id=${APP_ID}&key=${APP_KEY}`)
                  .then(res=>res.json())
                  .then(data=>{
                    setadminCourses(data.data)
                    $(document).ready(function () {
                      $('#coursesTable').DataTable();
                    });
                  })
              })
          } else {
            swal("Your data is safe!");
          }
      });
  }



  // STUDENT FUNCTIONS
  useEffect(()=>{
    setLoading(true)
    fetch(`${BASE_URL}get-all-courses-froms-students-by-check/${base64_encode(localStorage.getItem("user_id"))}?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(res=>res.json())
    .then(data=>{
        setLoading(false)
        setCourses(data.data)
        $(document).ready(function () {
          $('#myTable').DataTable();
        });
    })
  },[])


  return (
    <>
      {role==="admin" ? (
      <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-light ls-1 mb-1">
                      Overview
                    </h6>
                    <h2 className="text-white mb-0">Statistics</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <NavLink
                          className={classnames("py-2 px-3", {
                            active: activeNav === 1
                          })}
                          href="#pablo"
                          onClick={(e) => toggleNavs(e, 1)}
                        >
                          <span className="d-none d-md-block">Students Statistics</span>
                          <span className="d-md-none">M</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Bar
                    data={chartOne[chartExample1Data]}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Status
                    </h6>
                    <h2 className="mb-0">Students per Status</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Bar
                    data= {{
                      labels: ["ACTIVE", "PENDING", "COMPLETE"],
                      datasets: [
                        {
                          label: "Sales",
                          data: [
                            countDatas.active_students, countDatas.pending_students, countDatas.complete_students,
                          ],
                          backgroundColor: Colors,
                          borderColor: Colors,
                          maxBarThickness: 20,
                        },
                      ]
                    }}
                    options={chartExample1.options}
                    getDatasetAtEvent={(e) => console.log(e)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Courses</h3>
                  </div>
                  <div className="col text-right">
                    <Button
                      color="primary"
                      // href="#pablo"
                      onClick={(e) => history.push('/admin/courses-list')}
                      size="sm"
                    >
                      See all
                    </Button>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Table id="coursesTable" className="align-items-center table-flush" responsive>
                  <thead className="thead-dark">
                      <tr>
                      <th scope="col">NAME</th>
                      <th scope="col">PERIOD</th>
                      <th scope="col">FEES</th>
                      <th scope="col">REGISTED BY</th>
                      <th scope="col">CREATED AT</th>
                      <th scope="col" />
                      </tr>
                  </thead>
                  <tbody>
                      {adminCourses.map((data,idx)=>
                      <tr key={idx}>
                          <td>
                              <img alt="..." src={data.photo} style={{width:80, height:70, borderRadius:6}} /> {data.name}
                          </td>
                          <td>{data.period}</td>
                          <td>Tsh: {Intl.NumberFormat().format(data.fees)}</td>
                          <td>{data.registed_by}</td>
                          <td>{data.created_at}</td>
                          <td className="text-right">
                              <UncontrolledDropdown>
                              <DropdownToggle
                                  className="btn-icon-only text-dark"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                              >
                                  <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                  href="#pablo"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    deleteCourse(data.id)
                                  }}
                                  >
                                  Delete
                                  </DropdownItem>

                                  <DropdownItem
                                  to={`/update-course/${base64_encode(data.id.toString())}/${base64_encode(data.name)}`}
                                  tag={Link}
                                  >
                                  Update
                                  </DropdownItem>
                                  <DropdownItem
                                  to={`/course-content/${base64_encode(data.id.toString())}/${base64_encode(data.name)}`}
                                  tag={Link}
                                  >
                                  Content
                                  </DropdownItem>
                              </DropdownMenu>
                              </UncontrolledDropdown>
                          </td>
                      </tr>
                      )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      </>
      ):(
        <>{loading===true ? (<Indicator/>):(
        <>
        <PlainHeader/>
        <CardBody>
            <Row>
                <div className="col">
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <div>
                                <h3 className="mb-0">
                                  Available Courses
                                </h3>
                                {/* <small className="text-danger">NB: You can not remove course once selected</small> */}
                            </div>
                        </CardHeader>
                        <CardBody>
                          {/* <Table id="myTable" className="align-items-center table-flush" responsive>
                              <thead className="thead-dark">
                                  <tr>
                                  <th scope="col">NAME</th>
                                  <th scope="col">PERIOD</th>
                                  <th scope="col">FEES</th>
                                  <th scope="col">STATUS</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {courses.map((data,idx)=>
                                  <tr key={idx}>
                                      <th scope="row">
                                          <Label style={data.check > 0 ? {cursor:"not-allowed"} : {cursor:"pointer"}}>
                                              <input 
                                                  type="checkbox" 
                                                  disabled={data.check > 0 ? true : false}
                                                  style={{cursor:"pointer"}} 
                                                  checked={data.check > 0 ? true : false}
                                                  onChange={()=>{
                                                      addCoursesFunction(data.id)
                                                  }}
                                              />
                                              <span style={{margin:6}}>{data.name}</span>
                                          </Label>
                                      </th>
                                      <td>{data.period}</td>
                                      <td>Tsh: {Intl.NumberFormat().format(data.fees)}</td>
                                      <td>
                                          <span className={data.course_status==="COMPLETE" ? "btn-success p-1" : "btn-warning p-1"} style={data.check > 0 ? {borderRadius:4, fontWeight:"bold"} : {borderRadius:4, fontWeight:"bold", opacity:0.6, backgroundColor:"orange"}}>{data.course_status}</span>
                                      </td>
                                  </tr>
                                  )}
                              </tbody>
                          </Table> */}

                          <Row>
                            {courses.map((data,idx)=>
                            <Col key={idx} lg="6" xl="4" style={data.check > 0 ? {marginBottom:20, display:"none"} : {marginBottom:20}}>
                              <Card className="card-stats shadow mb-4 mb-xl-0">
                                <Link to={`/course-content/${base64_encode(data.id.toString())}/${base64_encode(data.name)}`}>
                                  <CardBody>
                                    {/* <img src={data.photo} style={{width:'100%', borderRadius:6}} /> */}
                                    <div
                                      style={{backgroundImage: `url(${data.photo})`, backgroundPosition:"center", backgroundSize:"cover", backgroundRepeat:"no-repeat", width:"100%", height:200,}}
                                    />
                                    <br/>
                                    <Row>
                                      <div className="col">
                                        <CardTitle
                                          tag="h5"
                                          className="text-uppercase text-muted mb-0"
                                        >
                                          {data.name}
                                        </CardTitle>
                                        <span className="h2 font-weight-bold mb-0"> Tsh: {Intl.NumberFormat().format(data.fees)}</span>
                                      </div>
                                      <Col className="col-auto">
                                        <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                          <i className={data.check > 0 ? "fas fa-check-circle" : "fas fa-chart-pie"} />
                                        </div>
                                      </Col>
                                    </Row>
                                    <p className="mt-3 mb-0 text-muted text-sm">
                                      <span className="text-danger mr-2">
                                        <i className="fas fa-clock" />
                                      </span>{" "}
                                      <span className="text-nowrap">{data.period}</span>
                                    </p>
                                  </CardBody>
                                </Link>
                              </Card>
                            </Col>
                            )}
                          </Row>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </CardBody>
        </>
        )}</>
      )}
    </>
  );
};

export default Index;
