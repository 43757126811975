import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/font.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import UpdateCourse from "views/examples/UpdateCourse";
import AddStudentCourses from "views/examples/AddStudentCourses";
import ViewStudent from "views/examples/ViewStudent";
import StudentCourseSettings from "views/examples/StudentCourseSettings";
import SystemAdmins from "views/examples/SystemAdmins";
import CourseContent from "views/examples/CourseContent";
import UpdateCourseContent from "views/examples/UpdateCourseContent";

const root = ReactDOM.createRoot(document.getElementById("root"));

const user_id = localStorage.getItem("user_id")

root.render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Route path="/update-course/:course_id/:course_name" render={(props)=><UpdateCourse {...props} />} />
      <Route path="/add-student-courses/:user_id/:full_name" render={(props)=><AddStudentCourses {...props} />} />
      <Route path="/view-student-profile/:student_id/:full_name" render={(props)=><ViewStudent {...props} />} />
      <Route path="/student-course-settings/:id/:full_name/:course_id/:course_name/:student_id" render={(props)=><StudentCourseSettings {...props} />} />

      <Route path="/course-content/:course_id/:course_name" render={(props)=><CourseContent {...props} />} />

      <Route path="/update-course-content/:content_id/:content_name" render={(props)=><UpdateCourseContent {...props} />} />

      <Route path="/system-admins-list" render={(props)=><SystemAdmins {...props} />} />

      {user_id ? <Redirect from="/" to="/admin/index" /> : <Redirect from="/" to="/auth/login" />}
    </Switch>
  </BrowserRouter>
);
