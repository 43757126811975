import { APP_ID } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { LEOLEO_URL } from "Api/Api";
import { useEffect, useState } from "react";
import {encode as base64_encode} from 'base-64';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col
} from "reactstrap";
import swal from "sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BASE_URL } from "Api/Api";
import Loader from "components/Loader/Loader";

const Register = () => {
  const [getRegions, setGetRegions] = useState([])
  const [districts, setgetDistricts] = useState([])
  const [region, setRegion] = useState("")
  const [district, setDistrict] = useState("")
  const [first_name, set_first_name] = useState("")
  const [last_name, set_last_name] = useState("")
  const [phone_number, set_phone_number] = useState("")
  const [email, set_email] = useState("")
  const [password, serPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const navigation = useHistory();


  useEffect(()=>{
      fetch(`${LEOLEO_URL}regions?app_id=${APP_ID}&key=${APP_KEY}`)
      .then(res=>res.json())
      .then(data=>{
          setGetRegions(data)
      })
      .catch((err)=>{
          swal({
              title: "Network Request Error",
              text: err.message,
              icon: "warning",
              button: "Okay",
          });
      })
  },[])

  function getDistricts(region_name) {
    setRegion(region_name)
    fetch(`${LEOLEO_URL}getRegionDistrictsByName/${base64_encode(region_name.toString())}?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(res=>res.json())
    .then(data=>{
        setgetDistricts(data)
    })
  }

  const registerStudentFunction = (event) =>{
    event.preventDefault();
    if (first_name==="" || last_name==="" || phone_number==="" || region==="" || district==="" || password==="") {
      swal({
        title: "Required Fields",
        text: "Please provide all required informations!",
        icon: "warning",
        button: "Okay",
      });
    } else {
      setLoading(true)
      const formData = new FormData();
      formData.append("first_name", base64_encode(first_name))
      formData.append("last_name", base64_encode(last_name))
      formData.append("phone_number", base64_encode(phone_number))
      formData.append("region", base64_encode(region))
      formData.append("district", base64_encode(district))
      formData.append("password", base64_encode(password))
      formData.append("status", base64_encode("PENDING"))
      formData.append("email", base64_encode(email.toString()))
      formData.append("updated_by", base64_encode("1"))
      formData.append("registed_by", base64_encode("1"))
      fetch(`${BASE_URL}signup-student?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
      .then(res=>res.json())
      .then(data=>{
          if (data.result==="SUCCESS") {
              localStorage.setItem("user_id", data.user_id.toString())
              localStorage.setItem("username", data.username)
              localStorage.setItem("role", data.role)
              localStorage.setItem("user_image", data.user_image)
              setLoading(false)
              navigation.push("/admin/index")
              swal({
                title: `Account Created`,
                text: data.message,
                icon: "success",
                button: "Okay",
              });
          } else {
              setLoading(false)
              swal({
                  title: "Failed to create Account",
                  text: data.message,
                  icon: "warning",
                  button: "Okay",
              });
          }
      })
      .catch((err)=>{
          setLoading(false)
          swal({
              title: "Network Request Error",
              text: err.message,
              icon: "warning",
              button: "Okay",
          });
      })
    }
  }

  return (
    <>
      <Col lg="6" md="8">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Please provide the following credentials</small>
            </div>
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input 
                    placeholder="First Name" 
                    type="text"
                    defaultValue={first_name}
                    onChange={(e)=>set_first_name(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input 
                    placeholder="Last Name" 
                    type="text" 
                    defaultValue={last_name}
                    onChange={(e)=>set_last_name(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-phone" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input 
                    placeholder="Phone Number" 
                    defaultValue={phone_number}
                    onChange={(e)=>set_phone_number(e.target.value)}
                    type="number" 
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative mb-3">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    defaultValue={email}
                    onChange={(e)=>set_email(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                  <select
                      className="form-control"
                      id="input-region"
                      required
                      defaultValue={region}
                      onChange={(e)=>getDistricts(e.target.value)}
                      >
                      <option>-- select region --</option>
                      {getRegions.map((data,idx)=>
                      <option key={idx} value={data.name}>{data.name}</option>
                      )}
                  </select>
              </FormGroup>

              <FormGroup>
                  <select
                      className="form-control"
                      id="input-district"
                      required
                      defaultValue={district}
                      onChange={(e)=>setDistrict(e.target.value)}
                      >
                      <option>-- select district --</option>
                      {districts.map((data,idx)=>
                      <option key={idx} value={data.name}>{data.name}</option>
                      )}
                  </select>
              </FormGroup>

              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    defaultValue={password}
                    onChange={(e)=>serPassword(e.target.value)}
                    autoComplete="new-password"
                  />
                </InputGroup>
              </FormGroup>
              
              {/* <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row> */}
              <div className="text-center">
                {loading===true ? (<Loader/>):(
                  <Button onClick={(e)=>registerStudentFunction(e)} className="mt-4" color="primary" type="button">
                    Create account
                  </Button>
                )}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Register;
