import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Container, Row, Col } from "reactstrap";

const CourseHeader = (props) => {
  const history = useHistory();
  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: "300px",
          backgroundImage: `url(${props.photo})`,
          backgroundSize: "cover",
          backgroundPosition: "center top"
        }}
      >
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12" md="10">
              <h1 className="display-2 text-white">{props.name}</h1>
              <p className="text-white mt-0 mb-5">
                {props.details}
              </p>
              <Button
                color="info"
                // href="#pablo"
                onClick={() => history.goBack()}
              >
                <i className="fa fa-angle-double-left"></i> Back
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CourseHeader;