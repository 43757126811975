import { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, Row, Table, UncontrolledDropdown } from "reactstrap";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { BASE_URL } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { APP_ID } from "Api/Api";
import React from "react";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { Link, useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Indicator from "components/Loader/Indicator";
import UserHeader from "components/Headers/UserHeader";
import swal from "sweetalert";
import Loader from "components/Loader/Loader";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

  
const ViewStudent = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const admin_id = localStorage.getItem('user_id')
  const role = localStorage.getItem('role')
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [student,setStudent] = useState({})

  const [region, setRegion] = useState("")
  const [district, setDistrict] = useState("")
  const [first_name, set_first_name] = useState("")
  const [middle_name, set_middle_name] = useState("")
  const [last_name, set_last_name] = useState("")
  const [phone_number, set_phone_number] = useState("")
  const [email, set_email] = useState("")
  const [status, set_status] = useState("")
  const [address, set_address] = useState("")
  const [sponsor_name, set_sponsor_name] = useState("")
  const [sponsor_phone, set_sponsor_phone] = useState("")
  const [sponsor_relation, set_sponsor_relation] = useState("")
  const [gender, set_gender] = useState("")

  const history = useHistory()

  if (!admin_id || role!=="admin") {
    history.push("/")
  }
  
  const user = useParams()

  document.addEventListener("DOMContentLoaded", function () {
    history.push(location.pathname)
  })

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return base64_decode(user.full_name);
  };

  const getApiData = () =>{
    fetch(`${BASE_URL}get-student-profile/${user.student_id}?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(res=>res.json())
    .then(data=>{
      setRegion(data.region)
      setDistrict(data.district)
      set_first_name(data.first_name)
      set_last_name(data.last_name)
      set_email(data.email)
      set_phone_number(data.phone_number)
      set_status(data.status)
      set_middle_name(data.middle_name)
      set_address(data.address)
      set_sponsor_name(data.sponsor_name)
      set_sponsor_phone(data.sponsor_phone)
      set_sponsor_relation(data.sponsor_relation)
      set_gender(data.gender)
      setLoading(false)
      setStudent(data)
      $(document).ready(function () {
        $('#myTable').DataTable();
      });
    })
  }

  useEffect(()=>{
    setLoading(true)
    getApiData()
  },[user.student_id, getApiData()])


    const activateUserStatus = (id, course_id, name)=>{
        swal({
            title: "Student not Activated",
            text: "Please activate student to proceed!",
            icon: "warning",
            closeOnClickOutside:false,
            closeOnEsc:false,
            buttons: true,
            dangerMode: true,
        })
          .then((willDelete) => {
            if (willDelete) {
                const formData = new FormData()
                formData.append("student_id", user.student_id)
                formData.append("updated_by", base64_encode(admin_id))
                formData.append("status", base64_encode("ACTIVE"))
                fetch(`${BASE_URL}activate-student-status?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
                .then(del=>del.json())
                .then(del=>{
                    history.push(`/student-course-settings/${base64_encode(id.toString())}/${user.full_name}/${base64_encode(course_id)}/${base64_encode(name)}/${user.student_id}`)
                    swal(`Poof! ${del.message}!`, {
                        icon: "success",
                    });
                })
            } else {
              swal("Activate Canceled!");
            }
        });
    }
    

    const updateStudentFunction = (event) =>{
        event.preventDefault();
        setIsLoading(true)
        const formData = new FormData();
        formData.append("student_id", user.student_id)
        formData.append("first_name", base64_encode(first_name))
        formData.append("middle_name", base64_encode(middle_name))
        formData.append("last_name", base64_encode(last_name))
        formData.append("address", base64_encode(address))
        formData.append("sponsor_name", base64_encode(sponsor_name))
        formData.append("sponsor_phone", base64_encode(sponsor_phone))
        formData.append("sponsor_relation", base64_encode(sponsor_relation))
        formData.append("gender", base64_encode(gender))
        formData.append("phone_number", base64_encode(phone_number))
        formData.append("region", base64_encode(region))
        formData.append("district", base64_encode(district))
        formData.append("status", base64_encode(status))
        formData.append("email", base64_encode(email.toString()))
        formData.append("updated_by", base64_encode(admin_id))
        fetch(`${BASE_URL}update-student?app_id=${APP_ID}&key=${APP_KEY}`, {method:"PUT", body:formData})
        .then(res=>res.json())
        .then(data=>{
            if (data.result==="SUCCESS") {
                setIsLoading(false)
                
                getApiData()

                swal({
                    title: `Student Updated`,
                    text: data.message,
                    icon: "success",
                    button: "Okay",
                });
            } else {
                setIsLoading(false)
                swal({
                    title: "Failed to update Student",
                    text: data.message,
                    icon: "warning",
                    button: "Okay",
                });
            }
        })
        .catch((err)=>{
            setIsLoading(false)
            swal({
                title: "Network Request Error",
                text: err.message,
                icon: "warning",
                button: "Okay",
            });
        })
    }

    const updateStudentPhoto = (photo)=>{
        const formData = new FormData();
        formData.append("student_id", user.student_id)
        formData.append("photo", photo)
        fetch(`${BASE_URL}update-student-photo?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
        .then(r=>r.json())
        .then(upphoto=>{
            getApiData()
        })
    }
  
    return (
      <>
        <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../../assets/img/brand/logo.png"),
          imgAlt: "..."
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <>{loading===true ? (<Indicator/>) :(
        <>
        <UserHeader full_name={base64_decode(user.full_name)} />
        {/* Page content */}
        <Container className="mt--7" fluid>
            <Form onSubmit={(e)=>updateStudentFunction(e)}>
            <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                <Card className="card-profile shadow">
                <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                        <Label style={{cursor:"pointer"}}>
                            <Input type="file" style={{display:"none"}} onChange={(e)=>updateStudentPhoto(e.target.files[0])} />
                            <img
                                alt="..."
                                className="rounded-circle"
                                src={`${student.user_image}`}
                                style={{width:180, height:180, border:"solid"}}
                            />
                        </Label>
                    </div>
                    </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                    <div className="d-flex justify-content-between">
                    {/* <Button
                        className="mr-4"
                        color="info"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        size="sm"
                    >
                        Connect
                    </Button>
                    <Button
                        className="float-right"
                        color="default"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        size="sm"
                    >
                        Message
                    </Button> */}
                    </div>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                    <Row>
                        <div className="col">
                            <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                                <div>
                                    {/* <span className="description">Friends</span> */}
                                    <span className={student.status==="ACTIVE" ? "btn-success p-1" : "btn-warning p-1"} style={{borderRadius:4, fontWeight:"bold"}}>{student.status}</span>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <div className="text-center">
                    <h3>
                        {student.first_name} {student.last_name} <span className="btn-success p-1" style={{borderRadius:4, fontWeight:"bold", fontSize:10}}>{student.gender}</span>
                        {/* <span className="font-weight-light">, 27</span> */}
                    </h3>
                    <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                        {student.region} - {student.district}
                    </div>
                    <div className="h5 mt-4">
                        <i className="ni business_briefcase-24 mr-2" />
                        Student
                    </div>
                    <div>
                        <i className="ni education_hat mr-2" />
                        SandC Technology Company Limited
                    </div>
                    <hr className="my-4" />
                    </div>
                </CardBody>
                </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
                
                <Card className="shadow">
                <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                    <Col xs="8">
                        <h3 className="mb-0">{base64_decode(user.full_name)}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                        <Button
                        color="primary"
                        // href="#pablo"
                        onClick={() => history.push(`/add-student-courses/${base64_encode(student.id)}/${base64_encode(`${student.first_name} ${student.last_name}`)}`)}
                        size="sm"
                        >
                        Courses
                        </Button>
                    </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    
                    <h6 className="heading-small text-muted mb-4">
                        User information
                    </h6>
                    <div className="pl-lg-">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                >
                                    First Name
                                </label>
                                <Input
                                    className="form-control"
                                    defaultValue={first_name}
                                    onChange={(e)=>set_first_name(e.target.value)}
                                    id="input-username"
                                    placeholder="First Name"
                                    type="text"
                                    required
                                />
                                </FormGroup>
                            </Col>


                            <Col lg="6">
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-middle_name"
                                >
                                    Middle Name
                                </label>
                                <Input
                                    className="form-control"
                                    defaultValue={middle_name}
                                    onChange={(e)=>set_middle_name(e.target.value)}
                                    id="input-middle_name"
                                    placeholder="Middle Name"
                                    type="text"
                                    required
                                />
                                </FormGroup>
                            </Col>
                            
                            <Col lg="6">
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-last"
                                >
                                    Last Name
                                </label>
                                <Input
                                    className="form-control"
                                    onChange={(e)=>set_last_name(e.target.value)}
                                    defaultValue={last_name}
                                    id="input-last"
                                    placeholder="Last Name"
                                    type="text"
                                    required
                                />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-email"
                                >
                                    Email address
                                </label>
                                <Input
                                    className="form-control"
                                    id="input-email"
                                    placeholder="jesse@example.com"
                                    onChange={(e)=>set_email(e.target.value)}
                                    defaultValue={email}
                                    type="text"
                                />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-phone_number"
                                >
                                    Phone Number
                                </label>
                                <Input
                                    className="form-control"
                                    onChange={(d)=>set_phone_number(d.target.value)}
                                    defaultValue={phone_number}
                                    id="input-phone_number"
                                    placeholder="Phone Number"
                                    type="text"
                                />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-gender"
                                    >
                                        Gender
                                    </label>
                                    <select
                                        className="form-control"
                                        onChange={(e)=>set_gender(e.target.value)}
                                        defaultValue={gender}
                                        id="input-gender"
                                    >
                                        <option value={student.gender}>{student.gender}</option>
                                        <option value={"MALE"}>MALE</option>
                                        <option value={"FEMALE"}>FEMALE</option>
                                    </select>
                                </FormGroup>
                            </Col>
                        

                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-status"
                                    >
                                        Status
                                    </label>
                                    <select
                                        className="form-control"
                                        onChange={(e)=>set_status(e.target.value)}
                                        defaultValue={status}
                                        id="input-status"
                                    >
                                        <option value={student.status}>{student.status}</option>
                                        <option value={"ACTIVE"}>ACTIVE</option>
                                        <option value={"PENDING"}>PENDING</option>
                                        <option value={"COMPLETE"}>COMPLETE</option>
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </CardBody>
                </Card>
                
            </Col>
            </Row>
            <br/>
            <Card className="shadow">
                <CardBody>
                    <div>
                        <h3>Location Details</h3>
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-region"
                                >
                                    Region
                                </label>
                                <Input
                                    className="form-control"
                                    onChange={(e)=>setRegion(e.target.value)}
                                    defaultValue={region}
                                    id="input-region"
                                    placeholder="Region"
                                    type="text"
                                />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-district"
                                    >
                                        District
                                    </label>
                                    <Input
                                        className="form-control"
                                        onChange={(e)=>setDistrict(e.target.value)}
                                        defaultValue={district}
                                        id="input-district"
                                        placeholder="District"
                                        type="text"
                                    />
                                </FormGroup>
                            </Col>


                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-address"
                                    >
                                        Address
                                    </label>
                                    <Input
                                        className="form-control"
                                        onChange={(e)=>set_address(e.target.value)}
                                        defaultValue={address}
                                        id="input-address"
                                        placeholder="Address"
                                        type="text"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>

                    <hr/>
                    <div>
                        <h3>Sponsor Details</h3>
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-sponsor_name"
                                >
                                    Sponsor Name
                                </label>
                                <Input
                                    className="form-control"
                                    onChange={(e)=>set_sponsor_name(e.target.value)}
                                    defaultValue={sponsor_name}
                                    id="input-sponsor_name"
                                    placeholder="Sponsor Name"
                                    type="text"
                                />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-sponsor_phone"
                                    >
                                        Sponsor Contact
                                    </label>
                                    <Input
                                        className="form-control"
                                        onChange={(e)=>set_sponsor_phone(e.target.value)}
                                        defaultValue={sponsor_phone}
                                        id="input-sponsor_phone"
                                        placeholder="Sponsor Contact"
                                        type="number"
                                    />
                                </FormGroup>
                            </Col>


                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-sponsor_relation"
                                    >
                                        Sponsor Relation
                                    </label>
                                    <Input
                                        className="form-control"
                                        onChange={(e)=>set_sponsor_relation(e.target.value)}
                                        defaultValue={sponsor_relation}
                                        id="input-sponsor_relation"
                                        placeholder="Sponsor Relation"
                                        type="text"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>


                    {isLoading===true ? (
                        <Loader/>
                    ):(
                        <Button color="primary">
                        Update
                        </Button>
                    )}
                </CardBody>
            </Card>
            <br/>
            <Row>
                <Col lg="12">
                    <Card className="shadow">
                        <CardBody>
                            <Table id="myTable" className="align-items-center table-flush" responsive>
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">NAME</th>
                                        {/* <th scope="col">PERIOD</th> */}
                                        <th scope="col">FEES</th>
                                        <th scope="col">PAYMENT STATUS</th>
                                        <th scope="col">COMPLETE STATUS</th>
                                        <th scope="col">REGISTED BY</th>
                                        <th scope="col">UPDATED BY</th>
                                        <th scope="col">STARTING DATE</th>
                                        <th scope="col">END DATE</th>
                                        <th scope="col" />
                                    </tr>
                                </thead>
                                {student.courses&&(
                                <tbody>
                                    {student.courses.map((data,idx)=>
                                    <tr key={idx}>
                                        <th scope="row">{data.name}</th>
                                        {/* <td>{data.period}</td> */}
                                        <td>
                                            Tsh: {Intl.NumberFormat().format(data.fees)}
                                            <br/>
                                            {data.discount_status==="YES" &&(
                                            <span className="btn-danger p-1" style={{fontSize:10, borderRadius:4}}>DISCOUNT</span>
                                            )}
                                        </td>
                                        <td>
                                            <span className={data.payment_status==="PAID" ? "btn-success p-1" : "btn-warning p-1"} style={{borderRadius:4,   fontWeight:"bold"}}>{data.payment_status}</span>
                                        </td>
                                        <td>
                                            <span className={data.status==="COMPLETED" ? "btn-success p-1" : "btn-warning p-1"} style={{borderRadius:4,   fontWeight:"bold"}}>{data.status}</span>
                                        </td>
                                        <td>{data.registed_by}</td>
                                        <td>{data.updated_by}</td>
                                        <td>{data.starting_date}</td>
                                        <td>{data.end_date}</td>
                                        <td className="text-right">
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                    className="btn-icon-only text-dark"
                                                    href="#pablo"
                                                    role="button"
                                                    size="sm"
                                                    color=""
                                                    onClick={(e) => e.preventDefault()}
                                                    >
                                                    <i className="fas fa-ellipsis-v" />
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-arrow" right>
                                                    <DropdownItem
                                                        onClick={(e)=>{
                                                            e.preventDefault()
                                                            if (student.status!=="ACTIVE") {
                                                                activateUserStatus(data.id, data.course_id, data.name)
                                                            } else {
                                                                history.push(`/student-course-settings/${base64_encode(data.id.toString())}/${user.full_name}/${base64_encode(data.course_id)}/${base64_encode(data.name)}/${user.student_id}`)
                                                            }
                                                        }}
                                                        // to={`/student-course-settings/${base64_encode(data.id.toString())}/${user.full_name}/${base64_encode(data.course_id)}/${base64_encode(data.name)}/${user.student_id}`}
                                                        tag={Link}
                                                        >
                                                        Settings
                                                    </DropdownItem>

                                                    <DropdownItem
                                                    to={`/course-content/${base64_encode(data.course_id.toString())}/${base64_encode(data.name)}`}
                                                    tag={Link}
                                                    >
                                                    Course Content
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>
                                    )}
                                </tbody>
                                )}
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            </Form>
        </Container>
        </>
        )}</>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
      </>
    );
  };
  
  export default ViewStudent;
  