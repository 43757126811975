export const Colors = [
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
    'rgb(100, 149, 237)',
]