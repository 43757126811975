import {
    Card,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Table,
    Row,
    CardBody
  } from "reactstrap";
  // core components
import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { APP_ID } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { BASE_URL } from "Api/Api";
import Indicator from "components/Loader/Indicator";
import swal from "sweetalert";
import {encode as base64_encode} from 'base-64';
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

  const CoursesList = () => {
    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const user_id = localStorage.getItem("user_id")
    const role = localStorage.getItem("role")

    if (!user_id || role!=="admin") {
        history.push("/")
    }

    useEffect(()=>{
        setLoading(true)
        fetch(`${BASE_URL}get-all-courses-registed?app_id=${APP_ID}&key=${APP_KEY}`)
        .then(res=>res.json())
        .then(data=>{
            setLoading(false)
            setCourses(data.data)
            $(document).ready(function () {
                $('#myTable').DataTable();
            });
        })
    },[])

    const deleteCourse = (id)=>{
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this course!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
          .then((willDelete) => {
            if (willDelete) {
                fetch(`${BASE_URL}delete-course-route/${base64_encode(id.toString())}?app_id=${APP_ID}&key=${APP_KEY}`)
                .then(del=>del.json())
                .then(del=>{
                    swal(`Poof! ${del.message}!`, {
                        icon: "success",
                    });
                    fetch(`${BASE_URL}get-all-courses-registed?app_id=${APP_ID}&key=${APP_KEY}`)
                    .then(res=>res.json())
                    .then(data=>{
                        setLoading(false)
                        setCourses(data.data)
                        $(document).ready(function () {
                            $('#myTable').DataTable();
                        });
                    })
                })
            } else {
              swal("Your data is safe!");
            }
        });
    }

    return (
        <>{loading===true ? (<Indicator/>):(
        <>
        {/* Page content */}
        <PlainHeader />
        <CardBody>
          {/* Table */}
            <Row>
                <div className="col">
                    <Card className="shadow">
                        {/* <CardHeader className="border-0">
                            <h3 className="mb-0">Courses list</h3>
                        </CardHeader> */}
                        <CardBody>
                            <Table id="myTable" className="align-items-center table-flush" responsive>
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">S/N</th>
                                        <th scope="col">NAME</th>
                                        <th scope="col">PERIOD</th>
                                        <th scope="col">FEES</th>
                                        <th scope="col">DISCOUNT</th>
                                        <th scope="col">REGISTED BY</th>
                                        <th scope="col">CREATED AT</th>
                                        <th scope="col">UPDATED AT</th>
                                        <th scope="col">UPDATED BY</th>
                                        <th scope="col" />
                                    </tr>
                                </thead>
                                <tbody>
                                    {courses.map((data,idx)=>
                                    <tr key={idx}>
                                        <td>{++idx}</td>
                                        <td>
                                            <img src={data.photo} style={{width:80, height:70, borderRadius:6}} alt="..." /> {data.name}
                                        </td>
                                        <td>{data.period}</td>
                                        <td>Tsh: {Intl.NumberFormat().format(data.fees)}</td>
                                        <td>
                                            <span className={data.discount ==="NO DISCOUNT" ? "btn-danger p-1" : "btn-success p-1"} style={{borderRadius:4}}>{data.discount}</span>
                                        </td>
                                        <td>{data.registed_by}</td>
                                        <td>{data.created_at}</td>
                                        <td>{data.updated_at}</td>
                                        <td>{data.updated_by}</td>
                                        <td className="text-right">
                                            <UncontrolledDropdown>
                                            <DropdownToggle
                                                className="btn-icon-only text-dark"
                                                href="#pablo"
                                                role="button"
                                                size="sm"
                                                color=""
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                <i className="fas fa-ellipsis-v" />
                                            </DropdownToggle>
                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                <DropdownItem
                                                href="#pablo"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    deleteCourse(data.id)
                                                }}
                                                >
                                                Delete
                                                </DropdownItem>

                                                <DropdownItem
                                                to={`/update-course/${base64_encode(data.id.toString())}/${base64_encode(data.name)}`}
                                                tag={Link}
                                                >
                                                Update
                                                </DropdownItem>

                                                <DropdownItem
                                                to={`/course-content/${base64_encode(data.id.toString())}/${base64_encode(data.name)}`}
                                                tag={Link}
                                                >
                                                Content
                                                </DropdownItem>
                                            </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>
                                    )}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </CardBody>
        </>
        )}</>
    );
  };
  
  export default CoursesList;
  