import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Row, Table, UncontrolledDropdown } from "reactstrap";
import {encode as base64_encode, decode as base64_decode} from 'base-64';
import { BASE_URL } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { APP_ID } from "Api/Api";
import swal from "sweetalert";
import Loader from "components/Loader/Loader";
import React from "react";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { Link, useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Indicator from "components/Loader/Indicator";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import CourseHeader from "components/Headers/CourseHeader";
import { SELCOM_URL } from "Api/Api";


const CourseContent = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [name,setName] = useState("")
  const [details,setDetails] = useState("")
  const admin_id = localStorage.getItem('user_id')
  const [loading, setLoading] = useState(false)
  const [payLoading, setpayLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [courseData, setCourseData] = useState([])
  const [coursePrice, setcoursePrice] = useState(null)
  const [singleCourse, setsingleCourse] = useState({})
  const history = useHistory()
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")
  let student_course_id = (new URLSearchParams(window.location.search)).get("student_course_id")

  if (!user_id) {
    history.push("/")
  }
  
  document.addEventListener("DOMContentLoaded", function () {
    history.push(location.pathname)
  })
  
  const params = useParams()

  useEffect(()=>{
    setIsLoading(true)
    if (student_course_id) {
        fetch(`${BASE_URL}get-student-single-course/${params.course_id}/${base64_encode(user_id)}/${student_course_id}?app_id=${APP_ID}&key=${APP_KEY}`)
        .then(res=>res.json())
        .then(data=>{
            setsingleCourse(data)
            $(document).ready(function () {
                $('#dataTable').DataTable();
            });
        })
    } else {
        fetch(`${BASE_URL}get-single-course-from-student/${params.course_id}/${base64_encode(user_id)}?app_id=${APP_ID}&key=${APP_KEY}`)
        .then(res=>res.json())
        .then(data=>{
          setsingleCourse(data.data)
        })
    }

    fetch(`${BASE_URL}get-course-content/${params.course_id}?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(res=>res.json())
    .then(data=>{
      setIsLoading(false)
      setCourseData(data.data)
      setcoursePrice(data.price)
      $(document).ready(function () {
        $('#myTable').DataTable();
      });
    })
  },[params.course_id, student_course_id, user_id])

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);


  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return base64_decode(params.course_name);
  };

  const updateCourseFunction = (event) =>{
    event.preventDefault()
    setLoading(true)
    const formData = new FormData();
    formData.append("course_id", params.course_id)
    formData.append("name", base64_encode(name))
    formData.append("details", base64_encode(details.toString()))
    formData.append("updated_by", base64_encode(admin_id))
    formData.append("registed_by", base64_encode(admin_id))
    fetch(`${BASE_URL}register-course-content?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
    .then(res=>res.json())
    .then(data=>{
        if (data.result==="SUCCESS") {
            setLoading(false)
            fetch(`${BASE_URL}get-course-content/${params.course_id}?app_id=${APP_ID}&key=${APP_KEY}`)
            .then(res=>res.json())
            .then(data=>{
                setIsLoading(false)
                setCourseData(data.data)
                $(document).ready(function () {
                    $('#myTable').DataTable();
                });
            })
            swal({
                title: data.result,
                text: data.message,
                icon: "success",
                button: "Okay",
            });
        } else {
            setLoading(false)
            swal({
                title: data.result,
                text: data.message,
                icon: "warning",
                button: "Okay",
            });
        }
    })
    .catch((err)=>{
        setLoading(false)
        swal({
            title: "Network Request Error",
            text: err.message,
            icon: "warning",
            button: "Okay",
        });
    })
}



const deleteCourse = (id)=>{
    swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this course content!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
            fetch(`${BASE_URL}delete-course-content/${base64_encode(id.toString())}?app_id=${APP_ID}&key=${APP_KEY}`)
            .then(del=>del.json())
            .then(del=>{
                swal(`Poof! ${del.message}!`, {
                    icon: "success",
                });
                
                fetch(`${BASE_URL}get-course-content/${params.course_id}?app_id=${APP_ID}&key=${APP_KEY}`)
                .then(res=>res.json())
                .then(data=>{
                    setIsLoading(false)
                    setCourseData(data.data)
                    $(document).ready(function () {
                        $('#myTable').DataTable();
                    });
                })
            })
        } else {
          swal("Your data is safe!");
        }
    });
}





const addCoursesFunction = (course_id) =>{
    setLoading(true)
    const formData = new FormData()
    formData.append("student_id", base64_encode(localStorage.getItem("user_id")))
    formData.append("course_id", course_id)
    formData.append("price", base64_encode(coursePrice.toString()))
    formData.append("registed_by", base64_encode("1"))
    fetch(`${BASE_URL}subscribe-course?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
    .then(res=>res.json())
    .then(data=>{
        setLoading(false)
        if (data.result==="SUCCESS") {
            history.push(`/admin/my-courses`)
            swal({
                title: data.result,
                text: data.message,
                icon: "success",
                button: "Okay",
            });
        } else {
            swal({
                title: data.result,
                text: data.message,
                icon: "warning",
                button: "Okay",
            });
        }
    })
    .catch((err)=>{
        swal({
            title: "Network Request Error",
            text: err.message,
            icon: "warning",
            button: "Okay",
        });
    })
  }


  const makePayments = () =>{
    setpayLoading(true)
    fetch(`${BASE_URL}create-order-id/${student_course_id}?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(create=>create.json())
    .then(create=>{
        if (create.result==="SUCCESS") {
            const webhookCallback = base64_encode(`${BASE_URL}selcom-webhook-url-sandc?course_duration=${singleCourse.period_in_days}`)
            const order_id = base64_encode(create.order_id)
            const buyer_name = base64_encode(singleCourse.student_full_name)
            const buyer_phone = base64_encode(singleCourse.student_phone)
            const buyer_email = base64_encode(singleCourse.student_email)
            const amount = base64_encode(singleCourse.fees)
            const no_of_items = base64_encode("1")
            const expiry = base64_encode("5")
            const callback = webhookCallback
            const formData = new FormData()
            formData.append("order_id", order_id)
            formData.append("buyer_name", buyer_name)
            formData.append("buyer_phone", buyer_phone)
            formData.append("buyer_email", buyer_email)
            formData.append("amount", amount)
            formData.append("no_of_items", no_of_items)
            formData.append("expiry", expiry)
            formData.append("callback", callback)
            fetch(`${SELCOM_URL}create_order.php?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
            .then(pay=>pay.json())
            .then(pay=>{
                setpayLoading(false)
                if (pay.result==="SUCCESS") {
                    const payment_url = pay.data[0]
                    window.open(`${base64_decode(payment_url.payment_gateway_url)}`,'_blank')
                } else {
                    swal({
                        title: pay.result,
                        text: pay.message,
                        icon: "warning",
                        button: "Okay",
                    });
                }
            })
            .catch((err)=>{
                setpayLoading(false)
                swal({
                    title: "Network Request Error",
                    text: err.message,
                    icon: "warning",
                    button: "Okay",
                });
            })
        } else {
            setpayLoading(true)
            swal({
                title: "FAIL",
                text: "Failed to create order !",
                icon: "warning",
                button: "Okay",
            });
        }
    })
    .catch((err)=>{
        setpayLoading(false)
        swal({
        title: "Network Request Error",
        text: err.message,
        icon: "warning",
        button: "Okay",
        });
    })
  }


  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../../assets/img/brand/logo.png"),
          imgAlt: "..."
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <>{isLoading===true ? (<Indicator/>) :(
        <>
        <PlainHeader />
        <CardBody>
            <Card>
                {role==='admin' ? (
                <CardBody>
                    <h6 className="heading-small text-muted mb-4">
                        <Button
                            color="primary"
                            // href="#pablo"
                            onClick={() => history.goBack()}
                            size="sm"
                            >
                            <i className="fa fa-angle-double-left"></i> back
                        </Button> {base64_decode(params.course_name)}
                    </h6>
                    <Form onSubmit={(event)=>updateCourseFunction(event)}>
                        <div className="pl-lg-">
                            <Row>
                                <Col lg="12">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-course-name"
                                            >
                                        Content name
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-course-name"
                                            placeholder="Content name"
                                            type="text"
                                            defaultValue={name}
                                            onChange={(e)=>setName(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                
                                <Col lg="12">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-course-Details"
                                            >
                                            Details
                                        </label>
                                        <textarea
                                            className="form-control"
                                            rows={10}
                                            id="input-course-Details"
                                            placeholder="Details"
                                            required
                                            onChange={(e)=>setDetails(e.target.value)}
                                        >{details}</textarea>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {loading===true ? (
                                <Loader/>
                            ):(
                                <Button color="primary">
                                submit
                                </Button>
                            )}
                        </div>
                    </Form>
                    <hr className="my-4" />

                    <Table id="myTable" className="align-items-center table-flush" responsive>
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">S/N</th>
                                <th scope="col">NAME</th>
                                <th scope="col">DETAILS</th>
                                <th scope="col">REGISTED BY</th>
                                <th scope="col">CREATED AT</th>
                                <th scope="col">UPDATED AT</th>
                                <th scope="col">UPDATED BY</th>
                                <th scope="col" />
                            </tr>
                        </thead>
                        <tbody>
                            {courseData.map((data,idx)=>
                            <tr key={idx}>
                                <th scope="row">{++idx}</th>
                                <th scope="row">{data.name}</th>
                                <td>{data.details}</td>
                                <td>{data.registed_by}</td>
                                <td>{data.created_at}</td>
                                <td>{data.updated_at}</td>
                                <td>{data.updated_by}</td>
                                <td className="text-right">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            className="btn-icon-only text-dark"
                                            href="#pablo"
                                            role="button"
                                            size="sm"
                                            color=""
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                            <DropdownItem
                                            // href="#pablo"
                                            onClick={(e) => {
                                                e.preventDefault()
                                                deleteCourse(data.id)
                                            }}
                                            >
                                            Delete
                                            </DropdownItem>

                                            <DropdownItem
                                            to={`/update-course-content/${base64_encode(data.id.toString())}/${base64_encode(data.name)}`}
                                            tag={Link}
                                            >
                                            Update
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </Table>
                </CardBody>
                ):(
                    <>
                    <CourseHeader name={base64_decode(params.course_name)} photo={singleCourse.photo} details={singleCourse.details} />
                    <CardBody>
                        {/* <h6 className="heading-small text-muted mb-4">
                            <Button
                                color="primary"
                                // href="#pablo"
                                onClick={() => history.goBack()}
                                size="sm"
                                >
                                <i className="fa fa-angle-double-left"></i> back
                            </Button> {base64_decode(params.course_name)}
                        </h6> */}
                        <Card className="shadow p-3">
                            <h3><u>To be covered</u></h3>
                            <Row>
                                <Col xl="7">
                                    <CardBody>
                                        {courseData.map((data,idx)=>
                                        <li key={idx}>{data.name}</li>
                                        )}
                                        <hr/>
                                        {loading===true ? (<Loader/>):(
                                        <>
                                        {singleCourse.check > 0 ? (
                                            <Button 
                                                color="success"
                                                disabled
                                                onClick={(e)=>{
                                                    e.preventDefault()
                                                    addCoursesFunction(params.course_id)
                                                }}
                                                style={{cursor:"not-allowed"}}
                                                >
                                                <i className="fa fa-check-circle"></i> Subscribed
                                            </Button>
                                        ):(
                                            <Button 
                                                color="primary"
                                                onClick={(e)=>{
                                                    e.preventDefault()
                                                    addCoursesFunction(params.course_id)
                                                }}
                                                >
                                                Subscribe
                                            </Button>
                                        )}
                                        </>
                                        )}
                                    </CardBody>
                                </Col>
                                <Col xl="5">
                                    <CardBody>
                                        {/* <i className="fa fa-laptop" style={{fontSize:100}}></i> */}
                                        <div
                                            className="shadow"
                                            style={{backgroundImage: `url(${singleCourse.photo})`, backgroundPosition:"center", backgroundSize:"cover", backgroundRepeat:"no-repeat", width:"100%", height:200, borderRadius:6}}
                                        />
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                        <br/>
                        <Card className="shadow">
                            <CardBody>
                                {singleCourse.phrasePayments&&(
                                    <>
                                    {singleCourse.phrasePayments.length > 0&&(
                                        <div>
                                            <h3>Phrase Payments</h3>
                                            <Table id="myTable" className="align-items-center table-flush" responsive>
                                                <thead className="thead-dark">
                                                    <tr>
                                                        <th scope="col">PHRASE</th>
                                                        <th scope="col">AMOUNT</th>
                                                        <th scope="col">RECEIPT</th>
                                                        <th scope="col">REGISTED BY</th>
                                                        <th scope="col">CREATED AT</th>
                                                    </tr>
                                                </thead>
                                                {singleCourse.phrasePayments&&(
                                                <tbody>
                                                {singleCourse.phrasePayments.map((data,idx)=>
                                                <tr key={idx}>
                                                    <th scope="row">{++idx}</th>
                                                    <td>Tsh: {Intl.NumberFormat().format(data.amount)}</td>
                                                    <td>
                                                        <img alt="..." src={`${data.payment_receipt}`} style={{width:100, height:120}} />
                                                    </td>
                                                    <td>{data.registed_by}</td>
                                                    <td>{data.created_at}</td>
                                                </tr>
                                                )}
                                                </tbody>
                                                )}
                                            </Table>
                                        </div>
                                    )}
                                    </>
                                )}

                                {singleCourse.payment_receipt&&(
                                    <Row>
                                        <Col lg="6">
                                            <h2>SINGLE PAYMENT</h2>
                                            <h1><i className="fa fa-check-circle text-success"></i> Tsh: {Intl.NumberFormat().format(singleCourse.fees)}</h1>
                                            <h1><i className="fa fa-clock text-primary"></i> {singleCourse.period}</h1>
                                        </Col>
                                        <Col lg="6" className="text-center">
                                            <p>Receipt</p>
                                            <img alt="..." src={`${singleCourse.payment_receipt}`} style={{width:200, height:120}} />
                                        </Col>
                                    </Row>
                                )}

                                {singleCourse.payment_status==="NOT PAID"&&(
                                    <>{singleCourse.countPhrasePayments < 1&&(
                                    <div>
                                        <h1>Accepted Payments</h1>
                                        <div style={{width:"40%"}}>
                                            <Row>
                                                <Col xl="6">
                                                    <img alt="..." src={require('../../assets/img/brand/selcom.png')} width="100"  />
                                                </Col>
                                                <Col xl="6">
                                                    {payLoading===true ? (<Loader/>):(
                                                    <Button 
                                                    onClick={(e)=>{
                                                        e.preventDefault()
                                                        makePayments()
                                                    }}
                                                    color="primary"
                                                    >
                                                        Pay now
                                                    </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                            <h3>For Bank Deposit please contact: 0765665034 for more information</h3>
                                        </div>
                                    </div>
                                    )}</>
                                )}
                            </CardBody>
                        </Card>
                    </CardBody>
                    </>
                )}
            </Card>
        </CardBody>
        </>
        )}</>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default CourseContent;