import PlainHeader from "components/Headers/PlainHeader";

export default function Indicator() {
    return(
        <>
        <PlainHeader/>
        <div style={{background:"transparent", width:"100%", alignItems:"center", justifyContent:"center", textAlign:"center", height:"100%"}}>
            <img
              style={{width:200, height:200, margin:200}}
              alt="Loading..."
              src={require("../../assets/img/theme/indicator.gif")}
            />
        </div>
        </>
    )
}