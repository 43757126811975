import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Row, Table } from "reactstrap";
import {encode as base64_encode, decode as base64_decode} from 'base-64';
import { BASE_URL } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { APP_ID } from "Api/Api";
import swal from "sweetalert";
import Loader from "components/Loader/Loader";
import React from "react";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Indicator from "components/Loader/Indicator";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';


const StudentCourseSettings = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [startingDate,setStartingDate] = useState("")
  const [endDate,setEndDate] = useState("")
  const [paymentStatus,setPaymentStatus] = useState("")
  const [completeStatus,setcompleteStatus] = useState("")
  const [paymentReceipt,setpaymentReceipt] = useState("")
  const [paymentReceiptTwo,setpaymentReceiptTwo] = useState("")
  const [amount,setAmount] = useState("")
  const admin_id = localStorage.getItem('user_id')
  const [loading, setLoading] = useState(false)
  const [phraseLoading, setphraseLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [courseData, setCourseData] = useState({})
  const history = useHistory()
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")

  if (!user_id || role!=="admin") {
    history.push("/")
  }

  document.addEventListener("DOMContentLoaded", function () {
    history.push(location.pathname)
  })
  
  const params = useParams()

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getApiData = () =>{
    fetch(`${BASE_URL}get-student-single-course/${params.course_id}/${params.student_id}/${params.id}?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(res=>res.json())
    .then(data=>{
        setStartingDate(data.starting_date)
        setEndDate(data.end_date)
        setPaymentStatus(data.payment_status)
        setcompleteStatus(data.status)
        setCourseData(data)
        setIsLoading(false)
        $(document).ready(function () {
            $('#myTable').DataTable();
        });
    })
  }

  useEffect(()=>{
    setIsLoading(true)
    getApiData()
  },[params.course_id,params.student_id,params.id, getApiData()])

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return `${base64_decode(params.full_name)} | ${base64_decode(params.course_name)}`;
  };
  

  const updateCourseFunction = (event) =>{
    event.preventDefault()
    if (paymentStatus==="PAID" && startingDate===endDate) {
        swal({
            title: "Failed",
            text: "Please update starting date!",
            icon: "warning",
            button: "Okay",
        });
    } else {
        setLoading(true)
        const formData = new FormData();
        if (paymentReceipt) {
            formData.append("id", params.id)
            formData.append("starting_date", base64_encode(startingDate))
            formData.append("payment_status", base64_encode(paymentStatus))
            formData.append("status", base64_encode(completeStatus))
            formData.append("student_id", params.student_id)
            formData.append("updated_by", base64_encode(admin_id))
            formData.append("payment_receipt", paymentReceipt)
            fetch(`${BASE_URL}update-student-course?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
            .then(res=>res.json())
            .then(data=>{
                getApiData()
                if (data.result==="SUCCESS") {
                    setLoading(false)
                    swal({
                        title: `Course Updated`,
                        text: data.message,
                        icon: "success",
                        button: "Okay",
                    });
                } else {
                    setLoading(false)
                    swal({
                        title: "Failed to update course",
                        text: data.message,
                        icon: "warning",
                        button: "Okay",
                    });
                }
            })
            .catch((err)=>{
                setLoading(false)
                swal({
                    title: "Network Request Error",
                    text: err.message,
                    icon: "warning",
                    button: "Okay",
                });
            })
        } else {
            formData.append("id", params.id)
            formData.append("starting_date", base64_encode(startingDate))
            formData.append("payment_status", base64_encode(paymentStatus))
            formData.append("status", base64_encode(completeStatus))
            formData.append("student_id", params.student_id)
            formData.append("updated_by", base64_encode(admin_id))
            fetch(`${BASE_URL}update-student-course?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
            .then(res=>res.json())
            .then(data=>{
                getApiData()
                if (data.result==="SUCCESS") {
                    setLoading(false)
                    swal({
                        title: `Course Updated`,
                        text: data.message,
                        icon: "success",
                        button: "Okay",
                    });
                } else {
                    setLoading(false)
                    swal({
                        title: "Failed to update course",
                        text: data.message,
                        icon: "warning",
                        button: "Okay",
                    });
                }
            })
            .catch((err)=>{
                setLoading(false)
                swal({
                    title: "Network Request Error",
                    text: err.message,
                    icon: "warning",
                    button: "Okay",
                });
            })
        }
    }
}


const addPhrasePayments = (e)=>{
    e.preventDefault();
    if (courseData.starting_date === courseData.end_date) {
        swal({
            title: "Failed",
            text: "Please update starting date !",
            icon: "warning",
            button: "Okay",
        });
    }else{
        setphraseLoading(true)
        const formData = new FormData();
        formData.append("registed_by", base64_encode(admin_id))
        formData.append("student_id", params.student_id)
        formData.append("course_id", params.course_id)
        formData.append("amount", base64_encode(amount))
        formData.append("updated_by", base64_encode(admin_id))
        formData.append("payment_receipt", paymentReceiptTwo)
        fetch(`${BASE_URL}course-phrase-payments?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
        .then(res=>res.json())
        .then(data=>{
            setphraseLoading(false)
            getApiData()
            if (data.result==="SUCCESS") {
                setLoading(false)
                swal({
                    title: data.result,
                    text: data.message,
                    icon: "success",
                    button: "Okay",
                });
            } else {
                setLoading(false)
                swal({
                    title: data.result,
                    text: data.message,
                    icon: "warning",
                    button: "Okay",
                });
            }
        })
        .catch((err)=>{
            setLoading(false)
            swal({
                title: "Network Request Error",
                text: err.message,
                icon: "warning",
                button: "Okay",
            });
        })
    }
}


  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../../assets/img/brand/logo.png"),
          imgAlt: "..."
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <>{isLoading===true ? (<Indicator/>) :(
        <>
        <PlainHeader />
        <CardBody>
            <Card>
                <CardBody>
                    <Form onSubmit={(event)=>updateCourseFunction(event)}>
                        <h6 className="heading-small text-muted mb-4">
                            <Button
                                color="primary"
                                // href="#pablo"
                                onClick={() => history.goBack()}
                                size="sm"
                                >
                                <i className="fa fa-angle-double-left"></i> back
                            </Button>
                        </h6>
                        <h3>{`${base64_decode(params.full_name)} | ${base64_decode(params.course_name)}`} [Tsh: {Intl.NumberFormat().format(courseData.price)}]</h3>
                        <div className="pl-lg-">
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-starting-date"
                                        >
                                        Starting Date
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-starting-date"
                                            placeholder="Starting Date"
                                            type="datetime"
                                            defaultValue={startingDate}
                                            onChange={(e)=>setStartingDate(e.target.value)}
                                            required
                                            disabled={courseData.payment_status==="PAID" || courseData.countPhrasePayments>0 ? true : false}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-end-date"
                                        >
                                        End Date
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-end-date"
                                            placeholder="End Date"
                                            type="text"
                                            disabled
                                            value={courseData.end_date}
                                            // defaultValue={courseData.end_date}
                                            // onChange={(e)=>setEndDate(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg="4">
                                    <span>
                                        PAYMENT STATUS
                                    </span>

                                    {courseData.payment_status==="PAID" ? (
                                    <FormGroup>
                                        <span className={courseData.payment_status==="PAID" ? "btn-success p-1" : "btn-warning p-1"} style={{borderRadius:4,   fontWeight:"bold"}}>{courseData.payment_status}</span>
                                    </FormGroup>
                                    ):(
                                    <>
                                    {courseData.countPhrasePayments===0?(
                                    <>
                                        <h2>Please use Phrase Payments Instead</h2>
                                    </>
                                    ):(
                                        <h2>Phrase Payments Started </h2>
                                    )}
                                    </>
                                    )}
                                </Col>
                                
                                {courseData.payment_status==="PAID"&&(
                                <Col lg="4">
                                    <span>
                                        COMPLETE STATUS
                                    </span>
                                    {courseData.status==="COMPLETED" ? (
                                    <FormGroup>
                                        <span className={courseData.status==="COMPLETED" ? "btn-success p-1" : "btn-warning p-1"} style={{borderRadius:4,   fontWeight:"bold"}}>{courseData.status}</span>
                                    </FormGroup>
                                    ):(
                                    <>
                                    <FormGroup>
                                        <label style={{paddingLeft:34}} className="btn btn-outline-success">
                                            <Input
                                                className="form-control-alternative"
                                                id="input-complete-status"
                                                type="radio"
                                                value={"COMPLETED"}
                                                name="complete_status"
                                                checked={completeStatus==="COMPLETED" ? true : false}
                                                defaultValue={completeStatus}
                                                onChange={(e)=>setcompleteStatus(e.target.value)}
                                                required
                                            />
                                            COMPLETED
                                        </label>
                                    </FormGroup>

                                    <FormGroup>
                                        <label style={{paddingLeft:34}} className="btn btn-outline-danger">
                                            <Input
                                                className="form-control-alternative"
                                                id="input-complete-status"
                                                type="radio"
                                                name="complete_status"
                                                value={"NOT COMPLETED"}
                                                checked={completeStatus==="NOT COMPLETED" ? true : false}
                                                defaultValue={completeStatus}
                                                onChange={(e)=>setcompleteStatus(e.target.value)}
                                                required
                                            />
                                            NOT COMPLETED
                                        </label>
                                    </FormGroup>
                                    </>
                                    )}
                                </Col>
                                )}

                                {/* {courseData.payment_status==="NOT PAID"} */}
                                <Col lg="4">
                                    {courseData.payment_receipt ? (
                                    <img src={courseData.payment_receipt} style={{width:"50%", height:200}} alt="..." />
                                    ):(
                                    <>
                                    {courseData.payment_status==="NOT PAID" ? (
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-payment-receipt"
                                            >
                                            Payment Receipt
                                            </label>
                                            <Input
                                                className="form-control"
                                                id="input-payment-receipt"
                                                type="file"
                                                // defaultValue={paymentReceipt}
                                                onChange={(e)=>setpaymentReceipt(e.target.files[0])}
                                                required={paymentStatus==="PAID" ? true : false}
                                                disabled={paymentStatus==="PAID" ? false : true}
                                            />
                                        </FormGroup>
                                    ):(
                                        <h2><i className="fa fa-check-circle text-success"></i> PHRASE PAYMENT</h2>
                                    )}
                                    </>
                                    )}
                                </Col>
                            </Row>
                            {courseData.status==="NOT COMPLETED"&&(
                                <>
                                {loading===true ? (
                                    <Loader/>
                                ):(
                                    <Button color="primary">
                                    Update
                                    </Button>
                                )}
                                </>
                            )}
                        </div>
                    </Form>
                    <hr className="my-4" />
                    <Form onSubmit={(e)=>addPhrasePayments(e)}>
                        <div className="bg-secondary">
                            <CardBody>
                                <h3>Phrase Payments</h3>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-amount"
                                                >
                                                Amount
                                            </label>
                                            <Input
                                                className="form-control-alternative"
                                                id="input-amount"
                                                placeholder="Amount"
                                                type="number"
                                                min={10000}
                                                required
                                                onChange={(e)=>setAmount(e.target.value)}
                                                // defaultValue={amount}
                                                disabled={courseData.payment_status==="PAID" ? true : false}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-payment-receipt-phrase"
                                            >
                                            Payment Receipt
                                            </label>
                                            <Input
                                                className="form-control"
                                                id="input-payment-receipt-phrase"
                                                type="file"
                                                // defaultValue={paymentReceiptTwo}
                                                onChange={(e)=>setpaymentReceiptTwo(e.target.files[0])}
                                                required
                                                disabled={courseData.payment_status==="PAID" ? true : false}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {courseData.payment_status!=="PAID"&&(
                                <>
                                {phraseLoading===true ? (
                                    <Loader/>
                                ):(
                                    <Button color="primary">
                                    Submit
                                    </Button>
                                )}
                                </>
                            )}
                            <br/>
                            <br/>
                                <Table id="myTable" className="align-items-center table-flush" responsive>
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">PHRASE</th>
                                            <th scope="col">AMOUNT</th>
                                            <th scope="col">RECEIPT</th>
                                            <th scope="col">REGISTED BY</th>
                                            <th scope="col">CREATED AT</th>
                                        </tr>
                                    </thead>
                                    {courseData.phrasePayments&&(
                                    <tbody>
                                    {courseData.phrasePayments.map((data,idx)=>
                                    <tr key={idx}>
                                        <th scope="row">{++idx}</th>
                                        <td>Tsh: {Intl.NumberFormat().format(data.amount)}</td>
                                        <td>
                                            <img src={`${data.payment_receipt}`} style={{width:100, height:120}} alt="..." />
                                        </td>
                                        <td>{data.registed_by}</td>
                                        <td>{data.created_at}</td>
                                    </tr>
                                    )}
                                    </tbody>
                                    )}
                                </Table>
                            </CardBody>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </CardBody>
        </>
        )}</>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default StudentCourseSettings;
