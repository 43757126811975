// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: Poppins-Medium;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); \n}\n\n@font-face {\n    font-family: Poppins-Regular;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "); \n}\n\n*{\n    font-family: Poppins-Regular;\n}", "",{"version":3,"sources":["webpack://./src/assets/plugins/nucleo/css/font.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,4CAAgC;AACpC;;AAEA;IACI,4BAA4B;IAC5B,4CAAiC;AACrC;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":["@font-face {\n    font-family: Poppins-Medium;\n    src: url('./Poppins-Medium.ttf'); \n}\n\n@font-face {\n    font-family: Poppins-Regular;\n    src: url('./Poppins-Regular.ttf'); \n}\n\n*{\n    font-family: Poppins-Regular;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
