import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  Table
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useEffect, useState } from "react";
import { BASE_URL } from "Api/Api";
import { APP_ID } from "Api/Api";
import { APP_KEY } from "Api/Api";
import {encode as base64_encode} from 'base-64';
import Loader from "components/Loader/Loader";
import Indicator from "components/Loader/Indicator";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import swal from "sweetalert";


const Profile = () => {
  const [user,setuserProfile] = useState({})
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [student,setStudent] = useState({})

  const [region, setRegion] = useState("")
  const [district, setDistrict] = useState("")
  const [first_name, set_first_name] = useState("")
  const [last_name, set_last_name] = useState("")
  const [phone_number, set_phone_number] = useState("")
  const [email, set_email] = useState("")
  const [oldPassword, setoldPassword] = useState("")
  const [passwordOne, setpasswordOne] = useState("")
  const [passwordTwo, setpasswordTwo] = useState("")

  const history = useHistory()
  
  if (!user_id) {
    history.push("/")
  }

  useEffect(()=>{
    setLoading(true)
    fetch(`${BASE_URL}get-user-profile/${base64_encode(user_id)}?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(res=>res.json())
    .then(data=>{
      setLoading(false)
      setuserProfile(data)
    })
  },[user_id])


  function changeAdminPasswordFunction(event) {
    event.preventDefault()
    if (passwordOne!==passwordTwo) {
      swal({
        title: "Password does not Match",
        text: "Please comfirm your password correctly!",
        icon: "warning",
        button: "Okay",
      });
    } else {
      setIsLoading(true)
      const formData = new FormData()
      formData.append("user_id", base64_encode(user_id))
      formData.append("passwordOne", base64_encode(oldPassword))
      formData.append("passwordTwo", base64_encode(passwordTwo))
      fetch(`${BASE_URL}update-admin-password?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
      .then(res=>res.json())
      .then(data=>{
        setIsLoading(false)
        swal({
          title: data.result,
          text: data.message,
          icon: "warning",
          button: "Okay",
        });
      })
      .catch((err)=>{
        setIsLoading(false)
        swal({
          title: "Network Request Error",
          text: err.message,
          icon: "warning",
          button: "Okay",
        });
      })
    }
  }



  function changeStudentPasswordFunction(event) {
    event.preventDefault()
    if (passwordOne!==passwordTwo) {
      swal({
        title: "Password does not Match",
        text: "Please comfirm your password correctly!",
        icon: "warning",
        button: "Okay",
      });
    } else {
      setIsLoading(true)
      const formData = new FormData()
      formData.append("user_id", base64_encode(user_id))
      formData.append("passwordOne", base64_encode(oldPassword))
      formData.append("passwordTwo", base64_encode(passwordTwo))
      fetch(`${BASE_URL}update-student-password?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
      .then(res=>res.json())
      .then(data=>{
        setIsLoading(false)
        swal({
          title: data.result,
          text: data.message,
          icon: "warning",
          button: "Okay",
        });
      })
      .catch((err)=>{
        setIsLoading(false)
        swal({
          title: "Network Request Error",
          text: err.message,
          icon: "warning",
          button: "Okay",
        });
      })
    }
  }


  // USER FUNCTIONS
  useEffect(()=>{
    setLoading(true)
    fetch(`${BASE_URL}get-student-profile/${base64_encode(localStorage.getItem("user_id"))}?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(res=>res.json())
    .then(data=>{
      setRegion(data.region)
      setDistrict(data.district)
      set_first_name(data.first_name)
      set_last_name(data.last_name)
      set_email(data.email)
      set_phone_number(data.phone_number)
      setLoading(false)
      setStudent(data)
      $(document).ready(function () {
        $('#coursesTable').DataTable();
      });
    })
  },[])


  const updateStudentPhoto = (photo)=>{
      const formData = new FormData();
      formData.append("student_id", base64_encode(localStorage.getItem("user_id")))
      formData.append("photo", photo)
      fetch(`${BASE_URL}update-student-photo?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
      .then(upphoto=>upphoto.json())
      .then(upphoto=>{
          localStorage.setItem("user_image", upphoto.photo)
          fetch(`${BASE_URL}get-student-profile/${base64_encode(localStorage.getItem("user_id"))}?app_id=${APP_ID}&key=${APP_KEY}`)
          .then(response=>response.json())
          .then(response=>{
              setRegion(response.region)
              setDistrict(response.district)
              set_first_name(response.first_name)
              set_last_name(response.last_name)
              set_email(response.email)
              set_phone_number(response.phone_number)
              setLoading(false)
              setStudent(response)
              $(document).ready(function () {
                $('#coursesTable').DataTable();
              });
          })
      })
  }

  // alert(localStorage.getItem("user_image"))


  return (
    <>{loading===true ? (<Indicator/>) :(
    <>
      <UserHeader full_name={localStorage.getItem('username')} />
      {/* Page content */}
      <>{role==="admin" ? (
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="card-profile shadow">
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={`${localStorage.getItem("user_image")}`}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                {/* <div className="d-flex justify-content-between">
                  <Button
                    className="mr-4"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Connect
                  </Button>
                  <Button
                    className="float-right"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Message
                  </Button>
                </div> */}
              </CardHeader>
              <CardBody className="pt-0 pt-md-4">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                      {/* <div>
                        <span className="heading">22</span>
                        <span className="description">Friends</span>
                      </div>
                      <div>
                        <span className="heading">10</span>
                        <span className="description">Photos</span>
                      </div>
                      <div>
                        <span className="heading">89</span>
                        <span className="description">Comments</span>
                      </div> */}
                    </div>
                  </div>
                </Row>
                <div className="text-center">
                  <h3>
                    {user.username}
                    {/* <span className="font-weight-light">, 27</span> */}
                  </h3>
                  <div className="h5 font-weight-300">
                    <i className="ni location_pin mr-2" />
                    Mbeya - Tanzania
                  </div>
                  <div className="h5 mt-4">
                    <i className="ni business_briefcase-24 mr-2" />
                    Admin
                  </div>
                  <div>
                    <i className="ni education_hat mr-2" />
                    SandC Technology Company Limited
                  </div>
                  <hr className="my-4" />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                  {localStorage.getItem("username")==="sandc_technology"&&(
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      // href="#pablo"
                      onClick={(e) => {
                        e.preventDefault()
                        history.push(`/system-admins-list`)
                      }}
                    >
                      System Admins
                    </Button>
                  </Col>
                  )}
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Username
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={user.username}
                            id="input-username"
                            placeholder="Username"
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="jesse@example.com"
                            defaultValue={user.email}
                            type="email"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            First name
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={user.first_name}
                            id="input-first-name"
                            placeholder="First name"
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Last name
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={user.last_name}
                            id="input-last-name"
                            placeholder="Last name"
                            type="text"
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
                <hr className="my-4" />

                <Form onSubmit={(e)=>changeAdminPasswordFunction(e)}>
                  <h6 className="heading-small text-muted mb-4">
                    Change Password
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-password"
                          >
                            Old Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            onChange={(e)=>setoldPassword(e.target.value)}
                            defaultValue={oldPassword}
                            id="input-password"
                            placeholder="Old Password"
                            type="password"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-new-password"
                          >
                            New Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-new-password"
                            placeholder="New Password"
                            onChange={(e)=>setpasswordOne(e.target.value)}
                            defaultValue={passwordOne}
                            type="password"
                            required
                          />
                        </FormGroup>
                      </Col>

                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-new-password-confirm"
                          >
                            Confirm New Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-new-password-confirm"
                            placeholder="Confirm New Password"
                            onChange={(e)=>setpasswordTwo(e.target.value)}
                            defaultValue={passwordTwo}
                            type="password"
                            required
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        {isLoading===true ? (<Loader/>):(
                        <Button
                          color="primary"
                          // href="#pablo"
                          >
                          Change Password
                        </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      ):(
        <Container className="mt--7" fluid>
            <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
                <Card className="card-profile shadow">
                <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                        <Label style={{cursor:"pointer"}}>
                            <Input type="file" style={{display:"none"}} onChange={(e)=>updateStudentPhoto(e.target.files[0])} />
                            <img
                                alt="..."
                                className="rounded-circle"
                                src={`${student.user_image}`}
                                style={{width:180, height:180, border:"solid"}}
                            />
                        </Label>
                    </div>
                    </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                    <div className="d-flex justify-content-between">
                    {/* <Button
                        className="mr-4"
                        color="info"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        size="sm"
                    >
                        Connect
                    </Button>
                    <Button
                        className="float-right"
                        color="default"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        size="sm"
                    >
                        Message
                    </Button> */}
                    </div>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4">
                    <Row>
                        <div className="col">
                            <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                                <div>
                                    {/* <span className="description">Friends</span> */}
                                    <span className={student.status==="ACTIVE" ? "btn-success p-1" : "btn-warning p-1"} style={{borderRadius:4, fontWeight:"bold"}}>{student.status}</span>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <div className="text-center">
                    <h3>
                        {student.first_name} {student.last_name}
                        {/* <span className="font-weight-light">, 27</span> */}
                    </h3>
                    <div className="h5 font-weight-300">
                        <i className="ni location_pin mr-2" />
                        {student.region} - {student.district}
                    </div>
                    <div className="h5 mt-4">
                        <i className="ni business_briefcase-24 mr-2" />
                        Student
                    </div>
                    <div>
                        <i className="ni education_hat mr-2" />
                        SandC Technology Company Limited
                    </div>
                    <hr className="my-4" />
                    </div>
                </CardBody>
                </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
                <Card className="shadow">
                <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                          <h3 className="mb-0">{user.full_name}</h3>
                      </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Form>
                    <h6 className="heading-small text-muted mb-4">
                        User information
                    </h6>
                    <div className="pl-lg-4">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-username"
                                >
                                    First Name
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    defaultValue={first_name}
                                    onChange={(e)=>set_first_name(e.target.value)}
                                    id="input-username"
                                    placeholder="First Name"
                                    type="text"
                                    disabled
                                />
                                </FormGroup>
                            </Col>
                            
                            <Col lg="6">
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-last"
                                >
                                    Last Name
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    onChange={(e)=>set_last_name(e.target.value)}
                                    defaultValue={last_name}
                                    id="input-last"
                                    placeholder="Last Name"
                                    type="text"
                                    disabled
                                />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-email"
                                >
                                    Email address
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    id="input-email"
                                    placeholder="jesse@example.com"
                                    onChange={(e)=>set_email(e.target.value)}
                                    defaultValue={email}
                                    type="email"
                                    disabled
                                />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-phone_number"
                                >
                                    Phone Number
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    onChange={(d)=>set_phone_number(d.target.value)}
                                    defaultValue={phone_number}
                                    id="input-phone_number"
                                    placeholder="Phone Number"
                                    type="text"
                                    disabled
                                />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                <label
                                    className="form-control-label"
                                    htmlFor="input-region"
                                >
                                    Region
                                </label>
                                <Input
                                    className="form-control-alternative"
                                    onChange={(e)=>setRegion(e.target.value)}
                                    defaultValue={region}
                                    id="input-region"
                                    placeholder="Region"
                                    type="text"
                                    disabled
                                />
                                </FormGroup>
                            </Col>

                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-district"
                                    >
                                        District
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        onChange={(e)=>setDistrict(e.target.value)}
                                        defaultValue={district}
                                        id="input-district"
                                        placeholder="District"
                                        type="text"
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    </Form>
                    <hr className="my-4" />
                    <Form onSubmit={(e)=>changeStudentPasswordFunction(e)}>
                      <h6 className="heading-small text-muted mb-4">
                        Change Password
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-password"
                              >
                                Old Password
                              </label>
                              <Input
                                className="form-control-alternative"
                                onChange={(e)=>setoldPassword(e.target.value)}
                                defaultValue={oldPassword}
                                id="input-password"
                                placeholder="Old Password"
                                type="password"
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-new-password"
                              >
                                New Password
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-new-password"
                                placeholder="New Password"
                                onChange={(e)=>setpasswordOne(e.target.value)}
                                defaultValue={passwordOne}
                                type="password"
                                required
                              />
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-new-password-confirm"
                              >
                                Confirm New Password
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-new-password-confirm"
                                placeholder="Confirm New Password"
                                onChange={(e)=>setpasswordTwo(e.target.value)}
                                defaultValue={passwordTwo}
                                type="password"
                                required
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="12">
                            {isLoading===true ? (<Loader/>):(
                            <Button
                              color="primary"
                              // href="#pablo"
                              >
                              Change Password
                            </Button>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Form>
                </CardBody>
                </Card>
            </Col>
            </Row>
            <br/>
            <Row>
                <Col lg="12">
                    <Card className="shadow">
                      <CardBody>
                        <Table id="coursesTable" className="align-items-center table-flush" responsive>
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">NAME</th>
                                    {/* <th scope="col">PERIOD</th> */}
                                    <th scope="col">FEES</th>
                                    <th scope="col">PAYMENT STATUS</th>
                                    <th scope="col">COMPLETE STATUS</th>
                                    <th scope="col">REGISTED BY</th>
                                    <th scope="col">UPDATED BY</th>
                                    <th scope="col">STARTING DATE</th>
                                    <th scope="col">END DATE</th>
                                </tr>
                            </thead>
                            {student.courses&&(
                            <tbody>
                                {student.courses.map((data,idx)=>
                                <tr key={idx}>
                                    <th scope="row">{data.name}</th>
                                    {/* <td>{data.period}</td> */}
                                    <td>Tsh: {Intl.NumberFormat().format(data.fees)}</td>
                                    <td>
                                        <span className={data.payment_status==="PAID" ? "btn-success p-1" : "btn-warning p-1"} style={{borderRadius:4,   fontWeight:"bold"}}>{data.payment_status}</span>
                                    </td>
                                    <td>
                                        <span className={data.status==="COMPLETED" ? "btn-success p-1" : "btn-warning p-1"} style={{borderRadius:4,   fontWeight:"bold"}}>{data.status}</span>
                                    </td>
                                    <td>{data.registed_by}</td>
                                    <td>{data.updated_by}</td>
                                    <td>{data.starting_date}</td>
                                    <td>{data.end_date}</td>
                                </tr>
                                )}
                            </tbody>
                            )}
                        </Table>
                      </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
      )}</>
    </>
    )}</>
  );
};

export default Profile;
