import { APP_ID } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { LEOLEO_URL } from "Api/Api";
import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Row } from "reactstrap";
import swal from "sweetalert";
import {encode as base64_encode} from 'base-64';
import Loader from "components/Loader/Loader";
import { BASE_URL } from "Api/Api";

export default function RegisterStudent() {
    const [getRegions, setGetRegions] = useState([])
    const [districts, setgetDistricts] = useState([])
    const [region, setRegion] = useState("")
    const [district, setDistrict] = useState("")
    const [first_name, set_first_name] = useState("")
    const [middle_name, set_middle_name] = useState("")
    const [gender, set_gender] = useState("")
    const [date_of_birth, set_date_of_birth] = useState("")
    const [birth_location, set_birth_location] = useState("")
    const [nationality, set_nationality] = useState("")
    const [address, set_address] = useState("")
    const [sponsor_name, set_sponsor_name] = useState("")
    const [sponsor_phone, set_sponsor_phone] = useState("")
    const [sponsor_relation, set_sponsor_relation] = useState("")
    const [last_name, set_last_name] = useState("")
    const [phone_number, set_phone_number] = useState("")
    const [email, set_email] = useState("")
    const [loading, setLoading] = useState(false)

    const history = useHistory()
    const user_id = localStorage.getItem("user_id")
    const role = localStorage.getItem("role")

    if (!user_id || role!=="admin") {
        history.push("/")
    }

    useEffect(()=>{
        fetch(`${LEOLEO_URL}regions?app_id=${APP_ID}&key=${APP_KEY}`)
        .then(res=>res.json())
        .then(data=>{
            setGetRegions(data)
        })
        .catch((err)=>{
            swal({
                title: "Network Request Error",
                text: err.message,
                icon: "warning",
                button: "Okay",
            });
        })
    },[])

    function getDistricts(region_name) {
        setRegion(region_name)
        fetch(`${LEOLEO_URL}getRegionDistrictsByName/${base64_encode(region_name.toString())}?app_id=${APP_ID}&key=${APP_KEY}`)
        .then(res=>res.json())
        .then(data=>{
            setgetDistricts(data)
        })
    }

    const registerStudentFunction = (event) =>{
        event.preventDefault();
        setLoading(true)
        const formData = new FormData();
        formData.append("first_name", base64_encode(first_name))
        formData.append("middle_name", base64_encode(middle_name))
        formData.append("last_name", base64_encode(last_name))
        formData.append("gender", base64_encode(gender))
        formData.append("date_of_birth", base64_encode(date_of_birth))
        formData.append("birth_location", base64_encode(birth_location))
        formData.append("nationality", base64_encode(nationality))
        formData.append("address", base64_encode(address))
        formData.append("sponsor_name", base64_encode(sponsor_name))
        formData.append("sponsor_phone", base64_encode(sponsor_phone))
        formData.append("sponsor_relation", base64_encode(sponsor_relation))
        formData.append("phone_number", base64_encode(phone_number))
        formData.append("region", base64_encode(region))
        formData.append("district", base64_encode(district))
        formData.append("status", base64_encode("PENDING"))
        formData.append("email", base64_encode(email.toString()))
        formData.append("updated_by", base64_encode(user_id))
        formData.append("registed_by", base64_encode(user_id))
        fetch(`${BASE_URL}register-student?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
        .then(res=>res.json())
        .then(data=>{
            if (data.result==="SUCCESS") {
                setLoading(false)
                history.push(`/add-student-courses/${base64_encode(data.reference)}/${base64_encode(`${first_name} ${last_name}`)}`)
                swal({
                    title: `Student Registed`,
                    text: data.message,
                    icon: "success",
                    button: "Okay",
                });
            } else {
                setLoading(false)
                swal({
                    title: "Failed to register Student",
                    text: data.message,
                    icon: "warning",
                    button: "Okay",
                });
            }
        })
        .catch((err)=>{
            setLoading(false)
            swal({
                title: "Network Request Error",
                text: err.message,
                icon: "warning",
                button: "Okay",
            });
        })
    }

    return(
        <>
        <PlainHeader />
        <CardBody>
            <Form onSubmit={(event)=>registerStudentFunction(event)}>
                <div className="pl-lg-">
                    <Card>
                        <CardBody>
                            <h6 className="heading-small text-muted mb-4">
                                Personal information
                            </h6>
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                        >
                                        First name
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-first-name"
                                        placeholder="First name"
                                        type="text"
                                        onChange={(e)=>set_first_name(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-middle-name"
                                        >
                                        Middle name
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-middle-name"
                                        placeholder="Middle name"
                                        type="text"
                                        onChange={(e)=>set_middle_name(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>


                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-last-name"
                                        >
                                        Last name
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-last-name"
                                        placeholder="Last name"
                                        type="text"
                                        onChange={(e)=>set_last_name(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>


                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-gender"
                                        >
                                        Gender
                                        </label>
                                        <select
                                        className="form-control"
                                        id="input-gender"
                                        onChange={(e)=>set_gender(e.target.value)}
                                        required
                                        >
                                            <option>--select--</option>
                                            <option value={"MALE"}>MALE</option>
                                            <option value={"MALE"}>FEMALE</option>
                                        </select>
                                    </FormGroup>
                                </Col>


                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-date-of-birth"
                                        >
                                        Date of birth
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-date-of-birth"
                                        placeholder="Date of birth"
                                        type="date"
                                        onChange={(e)=>set_date_of_birth(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>


                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-birth-location"
                                        >
                                        Birth Location
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-birth-location"
                                        placeholder="Birth Location"
                                        type="text"
                                        onChange={(e)=>set_birth_location(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>


                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-nationality"
                                        >
                                        Nationality
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-nationality"
                                        placeholder="Nationality"
                                        type="text"
                                        onChange={(e)=>set_nationality(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>


                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-phone-number"
                                        >
                                        Phone Number
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-phone-number"
                                        placeholder="Phone Number"
                                        type="number"
                                        required
                                        onChange={(e)=>set_phone_number(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-email"
                                        >
                                        Email address
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-email"
                                        placeholder="jesse@example.com"
                                        type="text"
                                        onChange={(e)=>set_email(e.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <br/>

                    <Card>
                        <CardBody>
                            <h6 className="heading-small text-muted mb-4">
                                Location Address
                            </h6>
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-region"
                                        >
                                        Region
                                        </label>
                                        <select
                                            className="form-control"
                                            id="input-region"
                                            required
                                            defaultValue={region}
                                            onChange={(e)=>getDistricts(e.target.value)}
                                            >
                                            <option>-- select region --</option>
                                            {getRegions.map((data,idx)=>
                                            <option key={idx} value={data.name}>{data.name}</option>
                                            )}
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-district"
                                        >
                                        District
                                        </label>
                                        <select
                                            className="form-control"
                                            id="input-district"
                                            required
                                            defaultValue={district}
                                            onChange={(e)=>setDistrict(e.target.value)}
                                            >
                                            <option>-- select district --</option>
                                            {districts.map((data,idx)=>
                                            <option key={idx} value={data.name}>{data.name}</option>
                                            )}
                                        </select>
                                    </FormGroup>
                                </Col>


                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-address"
                                        >
                                        Address
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-address"
                                        type="address"
                                        placeholder="Enter Address"
                                        onChange={(e)=>set_address(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <br/>

                    <Card>
                        <CardBody>
                            <h6 className="heading-small text-muted mb-4">
                                Sponsor Information
                            </h6>
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-sponsor_name"
                                        >
                                        Sponsor Name
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-sponsor_name"
                                        type="text"
                                        placeholder="Sponsor Name"
                                        onChange={(e)=>set_sponsor_name(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>

                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-sponsor_phone"
                                        >
                                        Sponsor Phone
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-sponsor_phone"
                                        type="text"
                                        placeholder="Sponsor Phone"
                                        onChange={(e)=>set_sponsor_phone(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>

                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-sponsor_relation"
                                        >
                                        Sponsor Relation
                                        </label>
                                        <Input
                                        className="form-control"
                                        id="input-sponsor_relation"
                                        type="text"
                                        placeholder="Sponsor Relation"
                                        onChange={(e)=>set_sponsor_relation(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            {loading===true ? (
                                <Loader/>
                            ):(
                                <Button color="primary">
                                Register
                                </Button>
                            )}
                        </CardBody>
                    </Card>
                </div>
                <hr className="my-4" />
            </Form>
        </CardBody>
        </>
    )
}