import PlainHeader from "components/Headers/PlainHeader";
import { useState } from "react";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Row } from "reactstrap";
import {encode as base64_encode} from 'base-64';
import { BASE_URL } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { APP_ID } from "Api/Api";
import swal from "sweetalert";
import Loader from "components/Loader/Loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function RegisterCourses() {
    const [name,setName] = useState("")
    const [period,setPeriod] = useState("")
    const [fees,setFees] = useState("")
    const [discount,setDiscount] = useState("")
    const [details,setDetails] = useState("")
    const admin_id = localStorage.getItem('user_id')
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const user_id = localStorage.getItem("user_id")
    const role = localStorage.getItem("role")

    if (!user_id || role!=="admin") {
        history.push("/")
    }

    const registerCourse = (event) =>{
        event.preventDefault()
        setLoading(true)
        const formData = new FormData();
        formData.append("name", base64_encode(name))
        formData.append("period", base64_encode(period))
        formData.append("fees", base64_encode(fees))
        formData.append("discount", base64_encode(discount))
        formData.append("details", base64_encode(details.toString()))
        formData.append("updated_by", base64_encode(admin_id))
        formData.append("registed_by", base64_encode(admin_id))
        fetch(`${BASE_URL}register-course?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
        .then(res=>res.json())
        .then(data=>{
            if (data.result==="SUCCESS") {
                setLoading(false)
                swal({
                    title: `Course Registed`,
                    text: data.message,
                    icon: "success",
                    button: "Okay",
                });
            } else {
                setLoading(false)
                swal({
                    title: "Failed to register course",
                    text: data.message,
                    icon: "warning",
                    button: "Okay",
                });
            }
        })
        .catch((err)=>{
            setLoading(false)
            swal({
                title: "Network Request Error",
                text: err.message,
                icon: "warning",
                button: "Okay",
            });
        })
    }

    return(
        <>
        <PlainHeader />
        <CardBody>
            <Card>
                <CardBody>
                    <Form onSubmit={(event)=>registerCourse(event)}>
                        <h6 className="heading-small text-muted mb-4">
                        Courses
                        </h6>
                        <div className="pl-lg-4">
                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-course-name"
                                        >
                                        Course name
                                        </label>
                                        <Input
                                        className="form-control-alternative"
                                        id="input-course-name"
                                        placeholder="Course name"
                                        type="text"
                                        // defaultValue={name}
                                        onChange={(e)=>setName(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-period"
                                        >
                                        Course Period (In days)
                                        </label>
                                        <Input
                                        className="form-control-alternative"
                                        id="input-period"
                                        placeholder="Course Period (In days)"
                                        type="number"
                                        min={1}
                                        // defaultValue={period}
                                        onChange={(e)=>setPeriod(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-course-fees"
                                        >
                                        Course Fees
                                        </label>
                                        <Input
                                        className="form-control-alternative"
                                        id="input-course-fees"
                                        placeholder="Course Fees"
                                        type="number"
                                        // defaultValue={fees}
                                        onChange={(e)=>setFees(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>

                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-course-discount"
                                        >
                                        Course Discount
                                        </label>
                                        <Input
                                        className="form-control-alternative"
                                        id="input-course-discount"
                                        placeholder="Course Discount"
                                        type="number"
                                        // defaultValue={discount}
                                        onChange={(e)=>setDiscount(e.target.value)}
                                        required
                                        />
                                    </FormGroup>
                                </Col>

                                <Col lg="12">
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-course-Details"
                                        >
                                        Details
                                        </label>
                                        <textarea
                                        className="form-control"
                                        rows={10}
                                        id="input-course-Details"
                                        placeholder="Details"
                                        required
                                        onChange={(e)=>setDetails(e.target.value)}
                                        ></textarea>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {loading===true ? (
                                <Loader/>
                            ):(
                                <Button color="primary">
                                Register
                                </Button>
                            )}
                        </div>
                        <hr className="my-4" />
                    </Form>
                </CardBody>
            </Card>
        </CardBody>
        </>
    )
}