import {
  Card,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Row,
  CardBody,
  Col,
  CardTitle,
  Button,
  Form,
  FormGroup,
  Input
} from "reactstrap";
import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { APP_ID } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { BASE_URL } from "Api/Api";
import Indicator from "components/Loader/Indicator";
import {encode as base64_encode} from 'base-64';
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import Loader from "components/Loader/Loader";
import swal from "sweetalert";


const AccountExpenses = () => {
  const [expenses, setExpenses] = useState([])
  const [loading, setLoading] = useState(false)
  const [submitloading, setSubmitLoading] = useState(false)
  const [balance, setAcountBalance] = useState({})
  const [amount, setAmount] = useState("")
  const [details, setDetails] = useState("")

  const history = useHistory()
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")

  if (!user_id || role!=="admin") {
      history.push("/")
  }

  const getApiData = () =>{
    setLoading(true)

    fetch(`${BASE_URL}get-account-balance?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(res=>res.json())
    .then(data=>{
      setAcountBalance(data)
    })

    fetch(`${BASE_URL}get-all-expenses?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(res=>res.json())
    .then(data=>{
        setLoading(false)
        setExpenses(data)
        $(document).ready(function () {
            $('#myTable').DataTable();
        });
    })
  }

  useEffect(()=>{
    getApiData()
  },[])

  const account = [
    {
      "id":1,
      "name":"Total Income",
      "amount":balance.total_income,
      "icon":"fa fa-chart-bar",
      "bg":"bg-success",
    },
    {
      "id":2,
      "name":"Expenses",
      "amount":balance.total_expenses,
      "icon":"fa fa-chart-line",
      "bg":"bg-warning",
    },
    {
      "id":3,
      "name":"Remain Balance",
      "amount":balance.remain_amount,
      "icon":"fa fa-chart-pie",
      "bg":"bg-info",
    },
  ]

  const addExpenses = (event)=>{
    event.preventDefault()
    setSubmitLoading(true)
    const formData = new FormData()
    formData.append("registed_by", base64_encode(user_id))
    formData.append("amount", base64_encode(amount))
    formData.append("details", base64_encode(details))
    fetch(`${BASE_URL}register-expense?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
    .then(res=>res.json())
    .then((res=>{
      getApiData()
      setSubmitLoading(false)
      swal({
        title: res.title,
        text: res.message,
        icon: "success",
        button: "Okay",
      });
    }))
  }


  return (
      <>{loading===true ? (<Indicator/>):(
      <>
      {/* Page content */}
      <PlainHeader />
      <CardBody>
        {/* Table */}
          <Row>
            {account.map((data,idx)=>
            <Col key={idx} lg="6" xl="4">
              <Card className="shadow card-stats mb-4 mb-xl-0">
                <Link>
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          {data.name}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">Tsh: {Intl.NumberFormat().format(data.amount)}</span>
                      </div>
                      <Col className="col-auto">
                        <div className={`icon icon-shape ${data.bg} text-white rounded-circle shadow`}>
                          <i className={data.icon} />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-muted text-sm">
                      <span className="text-danger mr-2">
                        <i className={data.icon} />
                      </span>{" "}
                      <span className="text-nowrap">{data.name}</span>
                    </p>
                  </CardBody>
                </Link>
              </Card>
            </Col>
            )}
          </Row>
          <br/>

          <Card className="shadow">
              <CardBody>
                  <Form onSubmit={(event)=>addExpenses(event)}>
                      <h6 className="heading-small text-muted mb-4">
                      Add Expenses
                      </h6>
                      <div className="pl-lg-">
                          <Row>
                              <Col lg="12">
                                  <FormGroup>
                                      <label
                                      className="form-control-label"
                                      htmlFor="input-course-discount"
                                      >
                                      Amount
                                      </label>
                                      <Input
                                      className="form-control"
                                      id="input-course-discount"
                                      placeholder="Course Discount"
                                      type="number"
                                      // defaultValue={amount}
                                      onChange={(e)=>setAmount(e.target.value)}
                                      required
                                      />
                                  </FormGroup>
                              </Col>

                              <Col lg="12">
                                  <FormGroup>
                                      <label
                                      className="form-control-label"
                                      htmlFor="input-course-Details"
                                      >
                                      Details
                                      </label>
                                      <textarea
                                      className="form-control"
                                      rows={10}
                                      id="input-course-Details"
                                      placeholder="Details"
                                      required
                                      onChange={(e)=>setDetails(e.target.value)}
                                      ></textarea>
                                  </FormGroup>
                              </Col>
                          </Row>
                          {submitloading===true ? (
                              <Loader/>
                          ):(
                              <Button color="primary">
                              submit
                              </Button>
                          )}
                      </div>
                      <hr className="my-4" />
                  </Form>
              </CardBody>
          </Card>

          <br/>
          
          <Row>
              <div className="col">
              <Card className="shadow">
                  {/* <CardHeader className="border-0">
                  <h3 className="mb-0">Students list</h3>
                  </CardHeader> */}
                  <CardBody>
                      <Table id="myTable" className="align-items-center table-flush" responsive>
                      <thead className="thead-dark">
                          <tr>
                            <th scope="col">S/N</th>
                            <th scope="col">AMOUNT</th>
                            <th scope="col">DETAILS</th>
                            <th scope="col">DONE BY</th>
                            <th scope="col">DATE</th>
                          </tr>
                      </thead>
                      <tbody>
                          {expenses.map((data,idx)=>
                          <tr key={idx}>
                              <td>{++idx}</td>
                              <td>{Intl.NumberFormat().format(data.amount)}</td>
                              <td>{data.details}</td>
                              <td>{data.registed_by}</td>
                              <td>{data.created_at}</td>
                          </tr>
                          )}
                      </tbody>
                      </Table>
                  </CardBody>
              </Card>
              </div>
          </Row>
      </CardBody>
      </>
      )}</>
  );
};

export default AccountExpenses;
