import MyCourses from "views/examples/MyCourses";
import Profile from "views/examples/Profile";
import Index from "views/Index";

var studentRoutes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/my-courses",
    name: "My Courses",
    icon: "fa fa-list text-primary",
    component: MyCourses,
    layout: "/admin"
  },
  {
    path: "/user-profile",
    name: "My Profile",
    icon: "fa fa-user text-danger",
    component: Profile,
    layout: "/admin"
  },
];

export default studentRoutes;
