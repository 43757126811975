import { useEffect, useState } from "react";
import Chart from "chart.js";
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Label,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions} from "variables/charts.js";

import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BASE_URL } from "Api/Api";
import { APP_ID } from "Api/Api";
import { APP_KEY } from "Api/Api";
import {encode as base64_encode} from 'base-64';
import swal from "sweetalert";
import Indicator from "components/Loader/Indicator";
import PlainHeader from "components/Headers/PlainHeader";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';


const MyCourses = (props) => {
  const [courses, setCourses] = useState([])
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const user_id = localStorage.getItem("user_id")

  if (!user_id) {
    history.push("/")
  }

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  // STUDENT FUNCTIONS
  useEffect(()=>{
    setLoading(true)
    fetch(`${BASE_URL}get-student-selected-courses/${base64_encode(localStorage.getItem("user_id"))}?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(res=>res.json())
    .then(data=>{
        setLoading(false)
        setCourses(data.data)
        $(document).ready(function () {
          $('#myTable').DataTable();
        });
    })
  },[])


  const addCoursesFunction = (course_id) =>{
    const formData = new FormData()
    formData.append("student_id", base64_encode(localStorage.getItem("user_id")))
    formData.append("course_id", base64_encode(course_id.toString()))
    formData.append("registed_by", base64_encode("1"))
    fetch(`${BASE_URL}add-courses-to-student?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
    .then(res=>res.json())
    .then(data=>{
        if (data.result==="SUCCESS") {
            fetch(`${BASE_URL}get-student-selected-courses/${base64_encode(localStorage.getItem("user_id"))}?app_id=${APP_ID}&key=${APP_KEY}`)
            .then(res=>res.json())
            .then(data=>{
                setLoading(false)
                setCourses(data.data)
                $(document).ready(function () {
                  $('#myTable').DataTable();
                });
            })
            swal({
                title: `Course Selected`,
                text: data.message,
                icon: "success",
                button: "Okay",
            });
        } else {
            fetch(`${BASE_URL}get-student-selected-courses/${base64_encode(localStorage.getItem("user_id"))}?app_id=${APP_ID}&key=${APP_KEY}`)
            .then(res=>res.json())
            .then(data=>{
                setLoading(false)
                setCourses(data.data)
                $(document).ready(function () {
                  $('#myTable').DataTable();
                });
            })
            swal({
                title: "Removed",
                text: data.message,
                icon: "warning",
                button: "Okay",
            });
        }
    })
    .catch((err)=>{
        swal({
            title: "Network Request Error",
            text: err.message,
            icon: "warning",
            button: "Okay",
        });
    })
  }

  return (
    <>{loading===true ? (<Indicator/>):(
    <>
    <PlainHeader/>
    <CardBody>
        <Row>
            <div className="col">
                <Card className="shadow">
                    <CardHeader className="border-0">
                        <div style={{display:"flex", justifyContent:"space-between"}}>
                            <h3 className="mb-0">
                                My Courses
                            </h3>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <Table id="myTable" className="align-items-center table-flush" responsive>
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">NAME</th>
                                    <th scope="col">PERIOD</th>
                                    <th scope="col">STARTING DATE</th>
                                    <th scope="col">ENDING DATE</th>
                                    <th scope="col">FEES</th>
                                    <th scope="col">STATUS</th>
                                    <th scope="col">PAYMENT STATUS</th>
                                    <th scope="col"/>
                                </tr>
                            </thead>
                            <tbody>
                                {courses.map((data,idx)=>
                                <tr key={idx}>
                                    <th scope="row">
                                        <Label style={data.check > 0 ? {cursor:"not-allowed"} : {cursor:"pointer"}}>
                                            <input 
                                                type="checkbox" 
                                                disabled={data.check > 0 ? true : false}
                                                style={{cursor:"pointer"}} 
                                                checked={data.check > 0 ? true : false}
                                                onChange={()=>{
                                                    addCoursesFunction(data.id)
                                                }}
                                            />
                                            <span style={{margin:6}}>{data.name}</span>
                                        </Label>
                                    </th>
                                    <td>{data.period}</td>
                                    <td>{data.starting_date}</td>
                                    <td>{data.end_date}</td>
                                    <td>Tsh: {Intl.NumberFormat().format(data.fees)}</td>
                                    <td>
                                        <span className={data.course_status==="COMPLETE" ? "btn-success p-1" : "btn-warning p-1"} style={data.check > 0 ? {borderRadius:4, fontWeight:"bold"} : {borderRadius:4, fontWeight:"bold", opacity:0.6, backgroundColor:"orange"}}>{data.course_status}</span>
                                    </td>
                                    <td>
                                        <span className={data.payment_status[0]==="PAID" ? "btn-success p-1" : "btn-warning p-1"} style={data.check > 0 ? {borderRadius:4, fontWeight:"bold"} : {borderRadius:4, fontWeight:"bold", opacity:0.6, backgroundColor:"orange"}}>{data.payment_status}</span>
                                    </td>
                                    <td className="text-right">
                                        <UncontrolledDropdown>
                                        <DropdownToggle
                                            className="btn-icon-only text-dark"
                                            href="#pablo"
                                            role="button"
                                            size="sm"
                                            color=""
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                            <DropdownItem
                                            to={`/course-content/${base64_encode(data.id.toString())}/${base64_encode(data.name)}?student_course_id=${base64_encode(data.student_course_id)}`}
                                            tag={Link}
                                            >
                                            Content
                                            </DropdownItem>
                                        </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </div>
        </Row>
    </CardBody>
    </>
    )}</>
  );
};

export default MyCourses;
