import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import RegisterStudent from "views/examples/RegisterStudent";
import RegisterCourses from "views/examples/RegisterCourses";
import CoursesList from "views/examples/CoursesList";
import StudentsList from "views/examples/StudentsList";
import CompleteStudents from "views/examples/CompleteStudents";
import PendingStudents from "views/examples/PendingStudents";
import AllStudents from "views/examples/AllStudents";
import AccountExpenses from "views/examples/AccountExpenses";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/register-courses",
    name: "Register Courses",
    icon: "fa fa-edit text-primary",
    component: RegisterCourses,
    layout: "/admin"
  },
  {
    path: "/courses-list",
    name: "Courses list",
    icon: "fa fa-list text-success",
    component: CoursesList,
    layout: "/admin"
  },
  {
    path: "/register-student",
    name: "Register Student",
    icon: "fa fa-edit text-danger",
    component: RegisterStudent,
    layout: "/admin"
  },
  {
    path: "/students-list",
    name: "Active Students",
    icon: "fa fa-eye text-info",
    component: StudentsList,
    layout: "/admin"
  },
  {
    path: "/pending-students-list",
    name: "Pending Students",
    icon: "fa fa-clock text-danger",
    component: PendingStudents,
    layout: "/admin"
  },
  {
    path: "/complete-students-list",
    name: "Complete Students",
    icon: "fa fa-check-circle text-success",
    component: CompleteStudents,
    layout: "/admin"
  },
  {
    path: "/all-students-list",
    name: "All Students",
    icon: "fa fa-users text-primary",
    component: AllStudents,
    layout: "/admin"
  },
  {
    path: "/account-and-expenses",
    name: "Account and Expenses",
    icon: "fa fa-folder-open text-danger",
    component: AccountExpenses,
    layout: "/admin"
  },
  {
    path: "/user-profile",
    name: "Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin"
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin"
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   icon: "ni ni-key-25 text-info",
  //   component: Login,
  //   layout: "/auth"
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth"
  // },
];

export default routes;
