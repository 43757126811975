import { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col
} from "reactstrap";
import swal from "sweetalert";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {encode as base64_encode} from 'base-64';
import { BASE_URL } from "Api/Api";
import Loader from "components/Loader/Loader";
import { APP_ID } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { Link } from "react-router-dom/cjs/react-router-dom.min";


const Login = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const navigation = useHistory();

  const LoginFunction = () =>{
    if (username==="" || password==="") {
      swal({
        title: "Required Fields",
        text: "Please provide all required informations!",
        icon: "warning",
        button: "Okay",
      });
    } else {
      setLoading(true)
      const formData = new FormData()
      setTimeout(() => {
        formData.append("username", base64_encode(username))
        formData.append("password", base64_encode(password))
        fetch(`${BASE_URL}admin-aouth-user-login?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
        .then(res=>res.json())
        .then(data=>{
          if (data.result==="SUCCESS") {
            localStorage.setItem("user_id", data.user_id.toString())
            localStorage.setItem("username", data.username)
            localStorage.setItem("role", data.role)
            localStorage.setItem("user_image", data.user_image)
            setLoading(false)
            navigation.push("/admin/index")
          } else {
            setLoading(false)
            swal({
              title: "Failed to Login",
              text: data.message,
              icon: "warning",
              button: "Okay",
            });
          }
        })
        .catch((err)=>{
          setLoading(false)
          swal({
            title: "Network Request Error",
            text: err.message,
            icon: "warning",
            button: "Okay",
          });
        })
      }, 2000);
    }
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign in with credentials</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Username"
                    type="text"
                    onChange={(e)=>setUsername(e.target.value)}
                    defaultValue={username}
                    autoComplete="new-email"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    onChange={(e)=>setPassword(e.target.value)}
                    defaultValue={password}
                    autoComplete="new-password"
                    required
                  />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id="customCheckLogin"
                  type="checkbox"
                  required
                />
                <label
                  className="custom-control-label"
                  htmlFor="customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div>
              <div className="text-center">
                {loading===true ? (
                  <Loader/>
                ):(
                  <Button onClick={()=>LoginFunction()} className="my-4" color="primary" type="button">
                    Sign in
                  </Button>
                )}

                <p><Link to="/auth/register" >Register now</Link></p>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
