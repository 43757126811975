import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Label, Row, Table } from "reactstrap";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { BASE_URL } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { APP_ID } from "Api/Api";
import React from "react";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Indicator from "components/Loader/Indicator";
import swal from "sweetalert";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';


const AddStudentCourses = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [courses, setCourses] = useState([])
  const admin_id = localStorage.getItem('user_id')
  const role = localStorage.getItem('role')
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  if (!admin_id || role!=="admin") {
    history.push("/")
  }
  
  const user = useParams()

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);


  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return base64_decode(user.full_name);
  };

  const getApiData = () =>{
    fetch(`${BASE_URL}get-all-courses-froms-students-by-check/${user.user_id}?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(res=>res.json())
    .then(data=>{
        setLoading(false)
        setCourses(data.data)
        $(document).ready(function () {
          $('#myTable').DataTable();
        });
    })
  }

  useEffect(()=>{
    setLoading(true)
    getApiData()
  },[user.user_id, getApiData()])


  const addCourseDiscount = (course_id, fees, discount) =>{
    const formData = new FormData()
    formData.append("student_id", user.user_id)
    formData.append("course_id", base64_encode(course_id.toString()))
    formData.append("fees", base64_encode(fees.toString()))
    formData.append("discount", base64_encode(discount.toString()))
    formData.append("updated_by", base64_encode(admin_id.toString()))
    fetch(`${BASE_URL}add-course-to-student-with-discount?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
    .then(res=>res.json())
    .then(data=>{
        if (data.result==="SUCCESS") {
          getApiData()
          swal({
              title: data.result,
              text: data.message,
              icon: "success",
              button: "Okay",
          });
        } else {
          getApiData()
            swal({
                title: data.result,
                text: data.message,
                icon: "warning",
                button: "Okay",
            });
        }
    })
    .catch((err)=>{
        swal({
            title: "Network Request Error",
            text: err.message,
            icon: "warning",
            button: "Okay",
        });
    })
  }

  const addCoursesFunction = (course_id,fees) =>{
    const formData = new FormData()
    formData.append("student_id", user.user_id)
    formData.append("course_id", base64_encode(course_id.toString()))
    formData.append("fees", base64_encode(fees.toString()))
    formData.append("registed_by", base64_encode(admin_id.toString()))
    fetch(`${BASE_URL}add-courses-to-student?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
    .then(res=>res.json())
    .then(data=>{
        if (data.result==="SUCCESS") {
          getApiData()
          swal({
              title: `Course Selected`,
              text: data.message,
              icon: "success",
              button: "Okay",
          });
        } else {
          getApiData()
            swal({
                title: "Removed",
                text: data.message,
                icon: "warning",
                button: "Okay",
            });
        }
    })
    .catch((err)=>{
        swal({
            title: "Network Request Error",
            text: err.message,
            icon: "warning",
            button: "Okay",
        });
    })
  }

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../../assets/img/brand/logo.png"),
          imgAlt: "..."
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <>{loading===true ? (<Indicator/>) :(
        <>
        <PlainHeader />
        <CardBody>
            <Row>
                <div className="col">
                    <Card className="shadow">
                        <CardHeader className="border-0">
                            <div style={{display:"flex", justifyContent:"space-between"}}>
                                <h3 className="mb-0">
                                    <Button
                                        color="primary"
                                        onClick={() => history.goBack()}
                                        size="sm"
                                        >
                                        <i className="fa fa-angle-double-left"></i> back
                                    </Button> Please check Courses
                                </h3>
                                <h3 className="mb-0">
                                    <Button
                                        color="success"
                                        onClick={() => history.push(`/admin/all-students-list`)}
                                        >
                                        Finish <i className="fa fa-angle-double-right"></i>
                                    </Button>
                                </h3>
                            </div>
                        </CardHeader>
                        <CardBody>
                          <Table id="myTable" className="align-items-center table-flush" responsive>
                              <thead className="thead-dark">
                                  <tr>
                                    <th scope="col">NAME</th>
                                    <th scope="col">PERIOD</th>
                                    <th scope="col">FEES</th>
                                    <th scope="col">DISCOUNT</th>
                                    <th scope="col">DISCOUNT STATUS</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {courses.map((data,idx)=>
                                  <tr key={idx}>
                                      <th scope="row">
                                        <Label style={data.check > 0 ?{cursor:"not-allowed"}:{cursor:"pointer"}}>
                                            <input 
                                                type="checkbox" 
                                                style={data.check > 0 ?{cursor:"not-allowed"}:{cursor:"pointer"}} 
                                                checked={data.check > 0 ? true : false}
                                                disabled={data.check > 0 ? true : false}
                                                onChange={()=>{
                                                  addCoursesFunction(data.id, data.fees)
                                                }}
                                            />
                                            <span style={{margin:6}}>{data.name}</span>
                                        </Label>
                                      </th>
                                      <td>{data.period}</td>
                                      <td>Tsh: {Intl.NumberFormat().format(data.fees)}</td>
                                      <td>
                                        <span className={data.discount ==="NO DISCOUNT" ? "btn-danger p-1" : "btn-success p-1"} style={{borderRadius:4}}>{data.discount}</span>
                                      </td>
                                      <td>
                                        {data.discount==="NO DISCOUNT" ? (
                                          <span className={'btn-warning p-1'} style={{borderRadius:4}}>NO DISCOUNT</span>
                                        ):(
                                        <Label style={data.check > 0 ?{cursor:"pointer"}:{cursor:"not-allowed"}}>
                                            <input 
                                                type="checkbox" 
                                                style={data.check > 0 ?{cursor:"pointer"}:{cursor:"not-allowed"}} 
                                                checked={data.discount_status ==="YES" ? true : false}
                                                disabled={data.check > 0 ? false : true}
                                                onChange={()=>{
                                                    addCourseDiscount(data.id, data.fees, data.discount_price)
                                                }}
                                            />
                                            <span style={{margin:6}}>{data.discount_status}</span>
                                        </Label>
                                        )}
                                      </td>
                                  </tr>
                                  )}
                              </tbody>
                          </Table>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </CardBody>
        </>
        )}</>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default AddStudentCourses;