import PlainHeader from "components/Headers/PlainHeader";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Row, Table, UncontrolledDropdown } from "reactstrap";
import {encode as base64_encode} from 'base-64';
import { BASE_URL } from "Api/Api";
import { APP_KEY } from "Api/Api";
import { APP_ID } from "Api/Api";
import swal from "sweetalert";
import Loader from "components/Loader/Loader";
import React from "react";
import { useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Indicator from "components/Loader/Indicator";
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';

const SystemAdmins = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [loading, setLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const user_id = localStorage.getItem("user_id")
  const role = localStorage.getItem("role")
  const [admins, setAdmins] = useState([])
  const [first_name, set_first_name] = useState("")
  const [last_name, set_last_name] = useState("")
  const [phone_number, set_phone_number] = useState("")
  const [email, set_email] = useState("")


  if (!user_id || role!=="admin" || localStorage.getItem("username")!=="sandc_technology") {
    history.push("/")
  }

  document.addEventListener("DOMContentLoaded", function () {
    history.push(location.pathname)
  })
  
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "System Admins";
  };

  useEffect(()=>{
    setIsLoading(true)
    fetch(`${BASE_URL}get-all-admins-registed/${base64_encode(user_id)}?app_id=${APP_ID}&key=${APP_KEY}`)
    .then(res=>res.json())
    .then(data=>{
        setIsLoading(false)
        setAdmins(data.data)
        $(document).ready(function () {
            $('#dataTable').DataTable();
        });
    })
  },[user_id])

  
  const changeAdminStatus = (admin_id) =>{
    swal({
        title: "Are you sure?",
        text: "You want to change admin status!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
            fetch(`${BASE_URL}change-admin-status/${base64_encode(admin_id)}?app_id=${APP_ID}&key=${APP_KEY}`)
            .then(ch=>ch.json())
            .then(ch=>{
                fetch(`${BASE_URL}get-all-admins-registed/${base64_encode(user_id)}?app_id=${APP_ID}&key=${APP_KEY}`)
                .then(res=>res.json())
                .then(data=>{
                    setIsLoading(false)
                    setAdmins(data.data)
                    $(document).ready(function () {
                        $('#dataTable').DataTable();
                    });
                })
                swal(`Poof! ${ch.message}!`, {
                    icon: "success",
                });
            })
            .catch((err)=>{
                setLoading(false)
                swal({
                    title: "Network Request Error",
                    text: err.message,
                    icon: "warning",
                    button: "Okay",
                });
            })
        } else {
          swal("Canceled!");
        }
    });
    
  }



  const registerStudentFunction = (event) =>{
    event.preventDefault();
    setLoading(true)
    const formData = new FormData();
    formData.append("first_name", base64_encode(first_name))
    formData.append("last_name", base64_encode(last_name))
    formData.append("phone_number", base64_encode(phone_number))
    formData.append("email", base64_encode(email.toString()))
    formData.append("updated_by", base64_encode(user_id))
    formData.append("registed_by", base64_encode(user_id))
    fetch(`${BASE_URL}register-admin?app_id=${APP_ID}&key=${APP_KEY}`, {method:"POST", body:formData})
    .then(res=>res.json())
    .then(data=>{
        if (data.result==="SUCCESS") {
            fetch(`${BASE_URL}get-all-admins-registed/${base64_encode(user_id)}?app_id=${APP_ID}&key=${APP_KEY}`)
            .then(res=>res.json())
            .then(data=>{
                setIsLoading(false)
                setAdmins(data.data)
                $(document).ready(function () {
                    $('#dataTable').DataTable();
                });
            })
            setLoading(false)
            swal({
                title: `Admin Registed`,
                text: data.message,
                icon: "success",
                button: "Okay",
            });
        } else {
            setLoading(false)
            swal({
                title: "Failed to register Admin",
                text: data.message,
                icon: "warning",
                button: "Okay",
            });
        }
    })
    .catch((err)=>{
        setLoading(false)
        swal({
            title: "Network Request Error",
            text: err.message,
            icon: "warning",
            button: "Okay",
        });
    })
}

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../../assets/img/brand/logo.png"),
          imgAlt: "..."
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <>{isLoading===true ? (<Indicator/>) :(
        <>
        <PlainHeader />
        <CardBody>
          {/* Table */}
            <Row>
                <div className="col">
                <Card className="shadow">
                    {/* <CardHeader className="border-0">
                    <h3 className="mb-0">Students list</h3>
                    </CardHeader> */}
                    <CardBody>
                        <Form onSubmit={(event)=>registerStudentFunction(event)}>
                            <h6 className="heading-small text-muted mb-4">
                            <Button
                                color="info"
                                onClick={() => history.goBack()}
                                size="sm"
                            >
                                <i className="fa fa-angle-double-left"></i> Back
                            </Button>{" "} Admin information
                            </h6>
                            <div className="pl-lg-4">
                                
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                            className="form-control-label"
                                            htmlFor="input-first-name"
                                            >
                                            First name
                                            </label>
                                            <Input
                                            className="form-control-alternative"
                                            id="input-first-name"
                                            placeholder="First name"
                                            type="text"
                                            defaultValue={first_name}
                                            onChange={(e)=>set_first_name(e.target.value)}
                                            required
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                            className="form-control-label"
                                            htmlFor="input-last-name"
                                            >
                                            Last name
                                            </label>
                                            <Input
                                            className="form-control-alternative"
                                            id="input-last-name"
                                            placeholder="Last name"
                                            type="text"
                                            defaultValue={last_name}
                                            onChange={(e)=>set_last_name(e.target.value)}
                                            required
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                            className="form-control-label"
                                            htmlFor="input-phone-number"
                                            >
                                            Phone Number
                                            </label>
                                            <Input
                                            className="form-control-alternative"
                                            id="input-phone-number"
                                            placeholder="Phone Number"
                                            type="number"
                                            required
                                            defaultValue={phone_number}
                                            onChange={(e)=>set_phone_number(e.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                            className="form-control-label"
                                            htmlFor="input-email"
                                            >
                                            Email address
                                            </label>
                                            <Input
                                            className="form-control-alternative"
                                            id="input-email"
                                            placeholder="jesse@example.com"
                                            type="email"
                                            defaultValue={email}
                                            onChange={(e)=>set_email(e.target.value)}
                                            required
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {loading===true ? (
                                    <Loader/>
                                ):(
                                    <Button color="primary">
                                        Register
                                    </Button>
                                )}
                            </div>
                        </Form>
                    </CardBody>
                    <CardBody>
                        <Table id="dataTable" className="align-items-center table-flush" responsive>
                        <thead className="thead-dark">
                            <tr>
                            <th scope="col">FIRST NAME</th>
                            <th scope="col">LAST NAME</th>
                            <th scope="col">PHONE</th>
                            <th scope="col">EMAIL</th>
                            <th scope="col">REGISTED BY</th>
                            <th scope="col">STATUS</th>
                            <th scope="col" />
                            </tr>
                        </thead>
                        <tbody>
                            {admins.map((data,idx)=>
                            <tr key={idx}>
                                <td>{data.first_name}</td>
                                <td>{data.last_name}</td>
                                <td>{data.phone_number}</td>
                                <td>{data.email}</td>
                                <td>{data.registed_by}</td>
                                <td>
                                    <span className={data.user_status===1 ? "btn-success p-1" : "btn-warning p-1"} style={{borderRadius:4, fontWeight:"bold"}}>{data.user_status===1 ? "ACTIVE" : "DISABLED"}</span>
                                </td>
                                <td className="text-right">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                            className="btn-icon-only text-dark"
                                            href="#pablo"
                                            role="button"
                                            size="sm"
                                            color=""
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                            <DropdownItem
                                            // to={`/view-student-profile/${base64_encode(data.id.toString())}/${base64_encode(`${data.first_name} ${data.last_name}`)}`}
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                changeAdminStatus(data.id)
                                            }}
                                            tag={Link}
                                            >
                                            {data.user_status===1 ? 'Deactivate' : 'Activate'}
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </td>
                            </tr>
                            )}
                        </tbody>
                        </Table>
                    </CardBody>
                </Card>
                </div>
            </Row>
        </CardBody>
        </>
        )}</>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default SystemAdmins;